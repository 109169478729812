import { createEvent, createStore } from "effector";

export enum NotificationType {
  WARNING = "WARNING",
  SUCCESS = "SUCCESS",
  INFO = "INFO",
}

export enum Notifications {
  UPLOAD_EXT_ERROR = "UPLOAD_TYPE_ERROR",
  UPLOAD_SIZE_ERROR = "UPLOAD_SIZE_ERROR",
  UPLOAD_ERROR = "UPLOAD_ERROR",

  SAVE_GROUP_ERROR = "SAVE_GROUP_ERROR",
  REMINDER = "REMINDER",

  MORE_ATTACHMENTS_INFO = "MORE_ATTACHMENTS_INFO",
  PREEXISTING_ATTACHMENTS_INFO = "PREEXISTING_ATTACHMENTS_INFO",

  RENDER_INFO = "RENDER_INFO",
  NO_PAID_FEATURES_INFO = "NO_PAID_FEATURES_INFO",
  NO_TOKENS_ERROR = "NO_TOKENS_ERROR",
}
export interface NotificationI {
  title: string;
  type: NotificationType;
  notification: Notifications;
  show_again?: boolean;
}

const notificationsArr: NotificationI[] = [
  {
    title: "There is a problem",
    type: NotificationType.WARNING,
    notification: Notifications.UPLOAD_SIZE_ERROR,
  },
  {
    title: "There is a problem",
    type: NotificationType.WARNING,
    notification: Notifications.UPLOAD_EXT_ERROR,
  },
  {
    title: "There is a problem",
    type: NotificationType.WARNING,
    notification: Notifications.UPLOAD_ERROR,
  },
  {
    title: "There is a problem",
    type: NotificationType.WARNING,
    notification: Notifications.SAVE_GROUP_ERROR,
    // info: <p>To save a group please decide what to do with descriptions of added objects. After you save group description will be applied to every object in the group.</p>
  },
  {
    title: "Reminder",
    type: NotificationType.WARNING,
    notification: Notifications.REMINDER,
  },
  {
    title: "Please add more beta attachments!",
    type: NotificationType.INFO,
    notification: Notifications.MORE_ATTACHMENTS_INFO,
    show_again: true,
  },
  {
    title: "Render info",
    type: NotificationType.INFO,
    notification: Notifications.RENDER_INFO,
  },
  {
    title: "Beta Testing - No Publicly Available Paid Features",
    type: NotificationType.INFO,
    notification: Notifications.NO_PAID_FEATURES_INFO,
  },
  {
    title: "There is a problem",
    type: NotificationType.WARNING,
    notification: Notifications.NO_TOKENS_ERROR,
  },
  {
    title: "Info",
    type: NotificationType.INFO,
    notification: Notifications.PREEXISTING_ATTACHMENTS_INFO,
  },
];

export const $notification = createStore<NotificationI | null>(null);
export const setNotification = createEvent<Notifications | null>();
export const toggleNotificationShow = createEvent();

$notification
  .on(setNotification, (_, notification) => {
    if (!notification) return null;
    const notificationItem = notificationsArr.find(
      (item) => item.notification === notification
    );
    return notificationItem || null;
  })
  .on(toggleNotificationShow, (state) => {
    if (!state || state.show_again === undefined) return;
    return {
      ...state,
      show_again: !state.show_again,
    };
  });

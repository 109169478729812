import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';
import './InputPhone.scss';


export default function InputPhone({ value, setValue, onBlur }: { value: string, setValue: (value: string) => void, onBlur: () => void }) {
    return (
        <PhoneInput
            country={"us"}
            placeholder="Enter your business phone"
            value={value}
            containerClass={'phoneInput_container'}
            inputClass={'phoneInput_input'}
            buttonClass={'phoneInput_dropdown-bttn'}
            dropdownClass={'phoneInput_dropdown'}
            onChange={(e) => setValue(e ? e.toString() : value)}
            onBlur={onBlur}
        />
    )
}

import * as R from 'ramda';
import { ModalType } from './type';
import { $modal, openModal, closeModal } from '.';
/* import { attachErrorEvent, errorEvent } from 'entities/error'; */


$modal
    .on(openModal, R.nthArg(1))
    .on(closeModal, R.always({ type: ModalType.NONE }));
/*     .on(errorEvent, R.always({ type: ModalType.ERROR }))
    .on(attachErrorEvent, R.always({ type: ModalType.ATTACH_ERROR })); */

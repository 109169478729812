import { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import { ReactComponent as ArrowSVG } from './icons/arrow.svg';
import './SelectForm.scss';


type SelectFormProps = {
    placeholder: string;
    options: Array<string>;
    state: string;
    setState: (value: string) => void;
    checked: boolean;
};

export default function SelectForm({ placeholder, options, state, setState, checked }: SelectFormProps) {
    const [opened, setOpened] = useState(false);
    const [errored, setErrored] = useState(false);

    const select = useCallback((option: string) => {
        setState(option);
        setOpened(false);
    }, [setState]);

    useEffect(() => void (checked && setErrored(!state)), [checked, state]);


    return (
        <div className="select-form">
            <div className={classNames({ 'select-form__input': true, 'select-form__input_errored': errored })}>
                {!state && <div className="select-form__placeholder">{placeholder}</div>}
                {state && <div className="select-form__value">{state}</div>}
                <div className="select-form__arrow-container" onClick={() => setOpened(prev => !prev)}>
                    <ArrowSVG className={classNames({ pointer: true, 'select-form__arrow_rotated': opened })} />
                </div>
            </div>
            {errored && <div className="select-form__error">Please fill in the field</div>}
            {
                opened
                &&
                <div className="select-form__options">
                    {options.map(option => (<div className="select-form__option" onClick={() => select(option)} key={option}>{option}</div>))}
                </div>
            }
        </div>
    );
}
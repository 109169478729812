import React, { useCallback, useState } from 'react';
import classNames from 'classnames';
import { useHotKey } from 'hooks';
import { Sketch, updateSketchName } from 'services/sketch';
import { copySketchFx, deleteSketchFx, updateSketchNameEvent } from 'entities/sketches';
import { setHint } from 'entities/hint';
import './SketchList.scss';


type SketchListProps = {
    sketch: Sketch;
    isSelected: boolean;
    index: number;
    setSelectedSketch: (sketch: Sketch) => void;
    openPrototype: (sketch: Sketch) => void;
};

export default function SketchList({ sketch, isSelected, index, setSelectedSketch, openPrototype }: SketchListProps) {
    const [isEdited, setIsEdited] = useState<boolean>(false);

    useHotKey('Escape', () => setIsEdited(false));

    return (
        <div >
            {isEdited ?
                <SketchNameInput sketch={sketch} index={index} close={() => setIsEdited(false)} />
                :
                <div
                    className={isSelected ? 'sketch-list__selected' : 'sketch-list'}
                    onClick={() => setSelectedSketch(sketch)}
                    onDoubleClick={() => openPrototype(sketch)}
                >
                    <p>{sketch.name}</p>
                    <div className="sketch-list__icons_wrapper">
                        <div className="sketch-list__icon open_icon" onClick={() => openPrototype(sketch)}
                            id='open_prototype-icon'
                            onMouseEnter={() => setHint({ id: "open_prototype-icon" })}
                            onMouseLeave={() => setHint(null)} />
                        <div className={classNames({
                            'sketch-list__icon': true,
                            'rename_icon': true,
                            'sketch-list__icon_hide': sketch.default,
                        })} onClick={() => !sketch.default && setIsEdited(true)} />
                        <div className={classNames({
                            'sketch-list__icon': true,
                            'copy_icon': true,
                            'sketch-list__icon_hide': sketch.default,
                        })} onClick={() => copySketchFx(sketch.id)} />
                        <div className={classNames({
                            'sketch-list__icon': true,
                            'delete_icon': true,
                            'sketch-list__icon_hide': sketch.default,
                        })} onClick={() => !sketch.default && deleteSketchFx(sketch.id)} />
                    </div>
                </div>}
        </div>
    );
}

type SketchNameInputProps = {
    sketch: Sketch;
    index: number;
    close: () => void;
};

function SketchNameInput({ sketch, index, close }: SketchNameInputProps) {
    const [name, setName] = useState(sketch.name);

    const handleInputKeyDown = useCallback((e: React.KeyboardEvent<HTMLInputElement>): void => {
        if (e.key !== 'Enter') return;
        if (name.trim() !== '') {
            updateSketchNameEvent({ name, index });
            updateSketchName({ name, sketchId: sketch.id });
        }
        close();
    }, [name, index, sketch, close]);

    return (
        <div className="sketch-list__name-input-wrapper">
            <input className="sketch-list__name-input"
                value={name}
                onChange={e => setName(e.target.value)}
                onKeyDown={handleInputKeyDown}
                autoFocus={true}
                maxLength={36}
                onBlur={() => close()} />
        </div>
    );
}

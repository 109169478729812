import { createStore, createEffect, createEvent } from 'effector';
import * as R from 'ramda';
import { recognize, loadMask, loadSketch, baseRender, baseRenderCheckStatus } from 'services/sketch';
import { Manager } from './Manager';
import { demoSketch } from './constants';


export const $manager = createStore<Manager>(new Manager(demoSketch));
export const $sketchFormChecked = createStore(false);

export const setSketchFormChecked = createEvent<boolean>();

export const recognizeFx = createEffect(recognize);
export const loadMaskFx = createEffect(loadMask);
export const loadSketchFx = createEffect(loadSketch);
export const makePrototypeFx = createEffect(baseRender);
export const checkPrototypeStatusFx = createEffect(baseRenderCheckStatus);

$sketchFormChecked
    .on(setSketchFormChecked, R.nthArg(1));

import { Color } from './Manager';


export const defaultMaskColor: [number, number, number, number] = [0, 114, 189, 190];

export const demoSketch = {
    id: crypto.randomUUID(),
    segments: [
        { id: '42a0ea8f-0ec0-4e75-b675-e82ccc531239', name: 0, attachments: [], description: 'Window with a birch frame, triple glazing, horizontal pivot handles, and a matte finish', color: [123, 21, 4, 255] as Color, groupId: null },
        { id: '42a0ea8f-0ec0-4e75-b675-e82ccc531238', name: 1, attachments: [], description: 'Concrete wall with a smooth finish, showcasing the textured imprint of cedarwood sheets', color: [12, 212, 45, 255] as Color, groupId: null },
        { id: '42a0ea8f-0ec0-4e75-b675-e82ccc531237', name: 2, attachments: [], description: 'Door handle in brushed steel with a utilitarian design, featuring concealed fixtures', color: [223, 121, 224, 255] as Color, groupId: null },
        { id: '42a0ea8f-0ec0-4e75-b675-e82ccc531236', name: 3, attachments: [], description: 'LED lighting fixture with a warm-cool color temperature and frosted glass', color: [70, 80, 144, 255] as Color, groupId: null },
    ],
    file: null,
    list: [
        { id: '42a0ea8f-0ec0-4e75-b675-e82ccc531239', constructor: 'Segment' as 'Segment' },
        { id: '42a0ea8f-0ec0-4e75-b675-e82ccc531238', constructor: 'Segment' as 'Segment' },
        { id: '42a0ea8f-0ec0-4e75-b675-e82ccc531237', constructor: 'Segment' as 'Segment' },
        { id: '42a0ea8f-0ec0-4e75-b675-e82ccc531236', constructor: 'Segment' as 'Segment' },
    ],
    groups: [],
    firstLoad: false,
    name: 'Untitled',
};

import { useCallback, useEffect, useState } from 'react';
import { useStore } from 'effector-react';
import * as R from 'ramda';
import Input from 'Components/Input';
import Select from 'Components/Select';
import Checkbox from 'Components/Checkbox';
import { ReactComponent as DeleteSVG } from './icons/delete.svg';
import { ReactComponent as SuccessSVG } from './icons/success.svg';
import { ReactComponent as DAvatarSVG } from './icons/d-avatar.svg';
import { $email, $userData, deleteUserFx, magicAuthFx, updateUserDataFx } from 'entities/user';
import Button from 'Components/Button';
import InputPhone from 'Components/PhoneInput';
import { SavingState, setSavingState } from 'entities/saving';
import { magic } from 'pages/Auth/Auth';
import { LeftSidePanel, setLeftSidePanel } from 'entities/leftSidePanel';
import './PersonalSettings.scss';


const professions = [{ key: 'Architecture', value: 'Architecture' }, { key: 'Interior design', value: 'Interior design' }, { key: 'Furniture design', value: 'Furniture design' }, { key: 'Industrial design', value: 'Industrial design' }, { key: 'Landscape design', value: 'Landscape design' }, { key: 'Visualization (CG)', value: 'Visualization (CG)' }, { key: 'Other', value: 'Other' }];
const teamMembers = [{ key: '1-10', value: '1-10' }, { key: '11-50', value: '11-50' }, { key: '50+', value: '50+' }];
const software = [{ key: 'Autodesk Revit', value: 'Autodesk Revit' }, { key: 'Navisworks', value: 'Navisworks' }, { key: 'STAAD.Pro', value: 'STAAD.Pro' }, { key: 'Rhino', value: 'Rhino' }, { key: 'SketchUp Pro', value: 'SketchUp Pro' }, { key: 'AutoCAD', value: 'AutoCAD' }, { key: 'Civil 3D', value: 'Civil 3D' }, { key: 'Cedreo', value: 'Cedreo' }, { key: 'ArchiCAD', value: 'ArchiCAD' }];

enum EmailChangingState {
    NONE = 'NONE',
    CHANGING = 'CHANGING',
    CHANGED = 'CHANGED',
}

export default function PersonalSettings() {
    const userData = useStore($userData);
    const email = useStore($email);
    const [firstName, setFirstName] = useState(userData.firstName);
    const [lastName, setLastName] = useState(userData.lastName);
    const [inputEmail, setInputEmail] = useState(email || '');
    const [earlyAccessRequested, setEarlyAccessRequested] = useState(false);
    const [profession, setProfession] = useState(userData.profession);
    const [businessPhone, setBusinessPhone] = useState(userData.businessPhone);
    const [businessWebsiteLink, setBusinessWebsiteLink] = useState(userData.businessWebsiteLink);
    const [businessName, setBusinessName] = useState(userData.businessName);
    const [businessEmail, setBusinessEmail] = useState(userData.businessEmail);
    const [isSameEmail, setIsSameEmail] = useState(false);
    const [teamSize, setTeamSize] = useState(userData.teamSize);
    const [linkedin, setLinkedin] = useState(userData.linkedin);
    const [earlyDesignSoftware, setEarlyDesignSoftware] = useState(userData.earlyDesignSoftware);
    const [emailIsChanging, setEmailIsChanging] = useState(EmailChangingState.NONE);
    const [deletingAccount, setDeletingAccount] = useState(false);

    if (!email) throw new Error('Email is null.');

    useEffect(() => setBusinessEmail(isSameEmail ? email : userData.businessEmail), [email, isSameEmail, userData]);

    const updateUserData = useCallback(() => {
        const newData = {
            firstName,
            lastName,
            businessName,
            profession,
            businessPhone,
            businessEmail,
            businessWebsiteLink,
            teamSize,
            linkedin,
            earlyDesignSoftware,
            subscribeNewsletter: userData.subscribeNewsletter,
        };
        if (R.equals(newData, userData)) return;
        setSavingState(SavingState.SAVING);
        updateUserDataFx(newData).then(() => setSavingState(SavingState.SAVED));
    }, [firstName, lastName, businessName, profession, businessPhone, businessEmail, businessWebsiteLink, teamSize, linkedin, earlyDesignSoftware, userData]);

    useEffect(() => {
        setEmailIsChanging(EmailChangingState.CHANGED);
        const timeout = setTimeout(() => setEmailIsChanging(EmailChangingState.NONE), 3000);

        return () => clearTimeout(timeout);
    }, [email]);

    const signInWithMagic = useCallback(async () => {
        setEmailIsChanging(EmailChangingState.CHANGING);
        const token = await magic.auth.loginWithMagicLink({ email: inputEmail, showUI: false });
        if (!token) throw new Error('Token not exist.');
        magicAuthFx({ token });
    }, [inputEmail]);

    useEffect(() => {
        const style = document.createElement("style");
        style.id = "hide-magic-link-style";
        style.innerHTML = `
            iframe[src*="magic.link"] {
                display: none !important;
            }
        `;

        document.head.appendChild(style);

        return () => {
            const existingStyle = document.getElementById("hide-magic-link-style");
            if (existingStyle) {
                existingStyle.remove();
            }
        };
    }, []);

    return (
        <div className="personal-settings">
            <div className="personal-settings__details">
                <div className="personal-settings__details-title">PERSONAL DETAILS</div>
                <div className="personal-settings__form">
                    <div className="personal-settings__form-label">EMAIL</div>
                    <div className="personal-settings__email-form-container">
                        <Input className="personal-settings__email-input" value={inputEmail} onChange={event => setInputEmail(event.target.value)} />
                        <Button size="medium" color="white" onClick={signInWithMagic}>UPDATE EMAIL</Button>
                        {emailIsChanging === EmailChangingState.CHANGING && <div className="personal-settings__email-note">Confirmation link sent to {inputEmail}</div>}
                        {emailIsChanging === EmailChangingState.CHANGED && <div className="personal-settings__email-note">Your email has been successfully updated</div>}
                    </div>
                </div>
                <div className="personal-settings__form">
                    <div className="personal-settings__form-label">FIRST NAME</div>
                    <Input value={firstName} onChange={(e) => setFirstName(e.currentTarget.value)} placeholder="Enter your first name" onBlur={updateUserData} />
                </div>
                <div className="personal-settings__form">
                    <div className="personal-settings__form-label">LAST NAME</div>
                    <Input value={lastName} onChange={(e) => setLastName(e.currentTarget.value)} placeholder="Enter your last name" onBlur={updateUserData} />
                </div>
                {deletingAccount ? <DeletingAccount setDeletingAccount={setDeletingAccount} /> : <DeleteAccountButton setDeletingAccount={setDeletingAccount} />}
                {earlyAccessRequested ? <EarlyAccessRequested /> : <EarlyAccess onClick={setEarlyAccessRequested} />}
            </div>
            <div className="personal-settings__details">
                <div className="personal-settings__details-title">BUSINESS DETAILS</div>
                <div className="personal-settings__form">
                    <div className="personal-settings__form-label">BUSINESS NAME</div>
                    <Input value={businessName} onChange={(e) => setBusinessName(e.currentTarget.value)} placeholder="Enter your business name" onBlur={updateUserData} />
                </div>
                <div className="personal-settings__form">
                    <div className="personal-settings__form-label">PROFESSION</div>
                    <Select items={professions} value={profession} setValue={setProfession} placeholder="Select your profession" onBlur={updateUserData} />
                </div>
                <div className="personal-settings__form">
                    <div className="personal-settings__form-label">BUSINESS PHONE</div>
                    <InputPhone value={businessPhone} setValue={setBusinessPhone} onBlur={updateUserData} />
                </div>
                <div className="personal-settings__form">
                    <div className="personal-settings__form-label">BUSINESS WEBSITE LINK</div>
                    <Input value={businessWebsiteLink} onChange={(e) => setBusinessWebsiteLink(e.currentTarget.value)} placeholder="Enter your website" onBlur={updateUserData} />
                </div>
                <div className="personal-settings__form">
                    <div className="personal-settings__form-label">BUSINESS EMAIL</div>
                    <div className="personal-settings__form-checkbox">
                        <Checkbox checked={isSameEmail} onChange={setIsSameEmail} />
                        <div>Same as account email</div>
                    </div>
                    <Input value={businessEmail} onChange={(e) => setBusinessEmail(e.currentTarget.value)} placeholder="Enter your business email" disabled={isSameEmail} type={'email'} onBlur={updateUserData} />
                </div>
                <div className="personal-settings__form">
                    <div className="personal-settings__form-label">TEAM MEMBERS</div>
                    <Select items={teamMembers} value={teamSize} setValue={setTeamSize} placeholder="Select team members" onBlur={updateUserData} />
                </div>
                <div className="personal-settings__form">
                    <div className="personal-settings__form-label">LINKEDIN</div>
                    <Input value={linkedin} onChange={(e) => setLinkedin(e.currentTarget.value)} placeholder="Enter your LinkedIn link" onBlur={updateUserData} />
                </div>
                <div className="personal-settings__form">
                    <div className="personal-settings__form-label">SOFTWARE YOU USE MOST FREQUENTLY IN EARLY DESIGN DEVELOPMENT</div>
                    <Select items={software} value={earlyDesignSoftware} setValue={setEarlyDesignSoftware} placeholder="Select a software you use" onBlur={updateUserData} />
                </div>
            </div>
        </div>
    );
}

type DeleteAccountButtonProps = {
    setDeletingAccount: React.Dispatch<React.SetStateAction<boolean>>;
};

function DeleteAccountButton({ setDeletingAccount }: DeleteAccountButtonProps) {
    return (
        <div className="personal-settings__delete-account-button" onClick={() => setDeletingAccount(true)}>
            <DeleteSVG />
            <div className="personal-settings__delete-account-text">DELETE ACCOUNT</div>
        </div>
    );
}

type DeletingAccountProps = {
    setDeletingAccount: React.Dispatch<React.SetStateAction<boolean>>;
};

function DeletingAccount({ setDeletingAccount }: DeletingAccountProps) {
    const deleteUser = useCallback(() => {
        setLeftSidePanel(LeftSidePanel.NONE);
        deleteUserFx().then(() => window.location.href = 'https://quarters-dev.site')
    }, []);

    return (
        <div className="personal-settings__deleting-account">
            <div className="personal-settings__deleting-account-question">Are you sure you want to delete your account?</div>
            <div className="personal-settings__deleting-account-options">
                <Button size="medium" color="white" onClick={deleteUser}>DELETE</Button>
                <Button size="medium" color="white" onClick={() => setDeletingAccount(false)}>CANCEL</Button>
            </div>
        </div>
    );
}

type EarlyAccessProps = {
    onClick: React.Dispatch<React.SetStateAction<boolean>>;
};

function EarlyAccess({ onClick }: EarlyAccessProps) {
    return (
        <div className="personal-settings__early-access">
            <DAvatarSVG className="personal-settings__early-access-d-avatar" />
            <div>We are planning to launch the fully featured io-BURO Beta in late 2024.</div>
            <div>Spots are capped. We are particularly interested in team applications. To increase your chances, please provide your business details.</div>
            <div>As a courtesy to those willing to participate in occasional test sessions and feedback surveys, we plan to offer generous trial periods and special pricing offers.</div>
            <Button size="medium" color="dark" className="personal-settings__early-access-button" onClick={() => onClick(true)}>
                <div>APPLY FOR EARLY ACCESS TO IO-BURO</div>
                <div className="personal-settings__early-access-button-icon">BETA</div>
            </Button>
        </div>
    );
}

function EarlyAccessRequested() {
    return (
        <div className="personal-settings__early-access-requested-container">
            <div className="personal-settings__early-access-requested">
                <DAvatarSVG className="personal-settings__early-access-d-avatar" />
                <div className="personal-settings__early-access-requested-success">
                    <SuccessSVG />
                </div>
                <div className="personal-settings__early-access-requested-content">
                    <div className="personal-settings__early-access-requested-text">You are on the waitlist to access io-buro</div>
                    <div className="personal-settings__early-access-requested-icon">BETA</div>
                </div>
            </div>
        </div>
    );
}

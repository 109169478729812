import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import classNames from 'classnames';
import TextareaAutosize from 'react-textarea-autosize';
import { Segment, useEditor, useSegment, useManager } from 'entities/sketch/Manager';
import { setElementForAttach } from 'entities/library';
import { AttachmentComponent } from '../Attachment';
import { ReactComponent as CheckboxSVG } from './icons/checkbox.svg';
import { ReactComponent as CheckboxCheckedSVG } from './icons/checkbox_ckecked.svg';
import { ReactComponent as DragHandleSVG } from './icons/drag_handle.svg';
import { ReactComponent as AddAttachSVG } from './icons/add_attach.svg';
import { ReactComponent as TriangleSVG } from './icons/triangle.svg';
import { ReactComponent as DeleteSVG } from './icons/delete.svg';
import { ReactComponent as ReadOnlySVG } from './icons/read_only.svg';
import { useElementHeight } from 'hooks';
import { Notifications, setNotification } from 'entities/notification';
import { setHint } from 'entities/hint';
import { SpriteState } from 'entities/sketch/Engine/Engine';
import './SegmentElement.scss';


type SegmentElementProps = {
    segment: Segment;
    index: number;
    realIndex: number;
    matchDefinition: undefined | ((e: React.MouseEvent<HTMLCanvasElement | HTMLDivElement, MouseEvent>, id?: string) => void);
};

export default function SegmentElement({ segment, index, realIndex, matchDefinition }: SegmentElementProps) {
    const editingGroup = useManager('editingGroup');
    const selectedElemets = useManager('selectedElements');
    const hoveredElements = useManager('hoveredElements');
    const manager = useManager('self');
    const editing = useEditor('editing');
    const attachments = useSegment(segment, 'attachments');
    const state = useSegment(segment, 'state');
    const description = useSegment(segment, 'description');
    const [prevDescription, setPrevDescription] = useState(description);
    const [attachmentsIsOpened, setAttachmentsIsOpened] = useState(false);
    const attachmentsElement = useRef<HTMLDivElement | null>(null);

    const selected = useMemo(() => selectedElemets.includes(segment), [selectedElemets, segment]);

    const handleSelect = useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (matchDefinition) return matchDefinition(e, segment.id);

        if (editingGroup) {
            const group = manager.editingGroup;
            if (!group) throw new Error('Group is not being edited.');
            if (!e.ctrlKey && !e.metaKey) return;
            if (group.segments.includes(segment)) throw new Error('Group has this segment.');
            else manager.addSegmentToGroup(segment, group, group.segments.length);
            return;
        }
        if (editing) return;
        let target: unknown = e.target;

        while (true) {
            if (!(target instanceof Element)) break;
            if (target === e.currentTarget) break;
            if (target.getAttribute('data-type') === 'action') return;
            target = target.parentElement;
        }

        manager.selectOneElement(segment);
    }, [segment, manager, editingGroup, editing, matchDefinition]);

    const handleCheckbox = useCallback(() => {
        if (matchDefinition) return;
        if (editingGroup || editing) return;
        if (selected) manager.unselectElement(segment);
        else manager.selectElement(segment);
    }, [selected, manager, segment, editing, editingGroup, matchDefinition]);

    useEffect(() => {
        if (attachments.length === 0) setAttachmentsIsOpened(false);
    }, [attachments]);

    const onMouseEnter = useCallback((e: React.MouseEvent<HTMLCanvasElement | HTMLDivElement, MouseEvent>) => {
        if (matchDefinition && (segment.description || segment.attachments.length) && (!manager.matchDefinition || !manager.matchDefinition.full)) manager.matchDefinition = { element: segment, full: false, x: e.clientX, y: e.clientY };
        else manager.hoveredElements = [segment];
    }, [segment, manager, matchDefinition]);

    const onMouseLeave = useCallback(() => {
        if (manager.matchDefinition && !manager.matchDefinition.full && manager.matchDefinition.element === segment) manager.matchDefinition = null;
        if (manager.hoveredElements[0] === segment) manager.hoveredElements = [];
    }, [segment, manager]);

    const onBlur = useCallback(() => {
        if (prevDescription === description) return;
        else manager.saveSketch();
    }, [manager, prevDescription, description]);

    const inputFocus = useCallback(() => {
        const input = document.getElementById(`segment_${segment.id}_input`);
        if (!input) throw new Error('Input not exist.');
        input.focus();
    }, [segment]);

    const [attachmentsHeignt, openAttachmentsHeignt, closeAttachmentsHeignt] = useElementHeight(attachmentsElement);

    const openAttachments = useCallback(() => {
        setAttachmentsIsOpened(prev => {
            if (prev) closeAttachmentsHeignt();
            else openAttachmentsHeignt();
            return !prev;
        });
    }, [openAttachmentsHeignt, closeAttachmentsHeignt]);

    const openAttachmentsWindow = useCallback(() => {
        if (editingGroup) return;
        setNotification(Notifications.MORE_ATTACHMENTS_INFO);
        setElementForAttach(segment);
    }, [editingGroup, segment]);

    return (
        <Draggable draggableId={segment.id} index={index}>
            {(provided, snapshot) => (
                <div {...provided.draggableProps} ref={provided.innerRef}>
                    <div
                        className={classNames({
                            segment__container: true,
                            segment__container_dragging: snapshot.isDragging,
                            segment__container_selected: selected,
                            segment__container_hidden: state === SpriteState.HIDE,
                            segment__container_hovered: hoveredElements[0] === segment,
                        })}>
                        <div
                            id={'element_' + segment.id}
                            className="segment"
                            onClick={e => handleSelect(e)}
                            onMouseEnter={onMouseEnter}
                            onMouseLeave={onMouseLeave}
                        >
                            <div className="segment__cell1">
                                <div {...provided.dragHandleProps} className="segment__drag-handler-container" tabIndex={-1} >
                                    <DragHandleSVG />
                                </div>
                                {
                                    selected
                                        ?
                                        <CheckboxCheckedSVG className="pointer" onClick={handleCheckbox} data-type="action" />
                                        :
                                        <CheckboxSVG className="pointer" onClick={handleCheckbox} data-type="action" />
                                }
                            </div>
                            <div className="segment__cell2">
                                <div
                                    id={`color_circle_${index}`}
                                    onMouseEnter={() => setHint({ id: 'color_circle_', index: index.toLocaleString() })}
                                    onMouseLeave={() => setHint(null)}
                                    className={classNames({ 'segment__color': true, 'segment__color_active': selected })}
                                    style={{ backgroundColor: `rgb(${segment.color[0]}, ${segment.color[1]}, ${segment.color[2]})` }}
                                    onClick={() => manager.changeToRandomColor(segment)}
                                    data-type="action"
                                />
                                <div>{segment.name.toString().padStart(3, '0')}</div>
                            </div>
                            <div className="segment__cell3" onClick={inputFocus} data-type="action">
                                <TextareaAutosize
                                    maxRows={4}
                                    id={`segment_${segment.id}_input`}
                                    value={description}
                                    onChange={e => segment.description = e.currentTarget.value}
                                    className="segment__description"
                                    placeholder="Type and/or attach to define"
                                    onFocus={() => setPrevDescription(description)}
                                    onBlur={onBlur}
                                    disabled={Boolean(editingGroup)}
                                />
                            </div>
                            <div className="segment__cell4">
                                {
                                    Boolean(attachments.length)
                                    &&
                                    <div className="segment__open-attachments" onClick={openAttachments} data-type="action">
                                        <span>{attachments.length}</span>
                                        <TriangleSVG className={classNames({ 'segment__cell4-triangle': true, 'segment__cell4-triangle_opened': attachmentsIsOpened })} />
                                    </div>
                                }
                            </div>
                            <div className="segment__cell5">
                                <div className={classNames({ 'segment__add-attach-icon-container': true, 'segment__add-attach-icon-container_disabled': editingGroup })} onClick={openAttachmentsWindow} data-type="action">
                                    <AddAttachSVG />
                                </div>
                            </div>
                            <div className="segment__cell6">
                                <div className="segment__delete-icon-container" onClick={() => manager.removeSegments([segment])} data-type="action">
                                    <DeleteSVG />
                                </div>
                            </div>
                            <div className="segment__bottom_cell" />
                            <div className="segment__bottom_cell" />
                            <div className="segment__bottom_cell" />
                            <div className="segment__bottom_cell" />
                            <div className="segment__bottom_cell" />
                            {
                                editingGroup
                                &&
                                <div className="segment__readonly-cell-container">
                                    <div className="segment__readonly-cell">
                                        <ReadOnlySVG />
                                        <span>Read only</span>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="segment__attachments" ref={attachmentsElement} style={{ height: attachmentsHeignt }}>
                            {attachments.map(attachment => <AttachmentComponent element={segment} attachment={attachment} key={attachment.id} />)}
                        </div>
                    </div>
                </div >
            )}
        </Draggable >
    );
}

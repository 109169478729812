import { produce } from 'immer';
import SelectForm from 'Components/SelectForm';
import ColorForm from 'Components/ColorForm';
import { DownloadFormTemplate } from '../DownloadForm';
import './Qualities.scss';


const materialDiversity = ['Rather uniform than diverse', 'Rather diverse than uniform'];
const prevailingMaterials = ['Rather natural than artificial', 'Rather artificial than natural'];
const dominantTextures = ['Rather plain than active', 'Rather active than plain'];
const dominantSurfaces = ['Rather polished than matte', 'Rather matte than shiny'];
const detailIntegration = ['Rather integrated than utilitarian', 'Rather utilitarian than integrated'];
const paletteDiversity = ['Rather monochrome than colorful', 'Rather colorful than monochrome'];
const paletteTemperature = ['Rather cold than warm', 'Rather warm than cold'];

type QualitiesProps = {
    form: DownloadFormTemplate;
    setForm: React.Dispatch<React.SetStateAction<DownloadFormTemplate>>;
    checked: boolean;
};

export default function Qualities({ form, setForm, checked }: QualitiesProps) {
    return (
        <div className="qualities">
            <div className="qualities__col">
                <div className="qualities__col-title">MATERIAL QUALITIES</div>
                <div className="qualities__form">
                    <SelectForm options={materialDiversity} state={form.materialDiversity} setState={value => setForm(produce(form, form => void (form.materialDiversity = value)))} checked={checked} placeholder="How diverse are the design materials?" />
                    <SelectForm options={prevailingMaterials} state={form.prevailingMaterials} setState={value => setForm(produce(form, form => void (form.prevailingMaterials = value)))} checked={checked} placeholder="Which materials prevail?" />
                    <SelectForm options={dominantTextures} state={form.dominantTextures} setState={value => setForm(produce(form, form => void (form.dominantTextures = value)))} checked={checked} placeholder="Which material textures dominate in the design?" />
                    <SelectForm options={dominantSurfaces} state={form.dominantSurfaces} setState={value => setForm(produce(form, form => void (form.dominantSurfaces = value)))} checked={checked} placeholder="Which surfaces dominate in the design?" />
                    <SelectForm options={detailIntegration} state={form.detailIntegration} setState={value => setForm(produce(form, form => void (form.detailIntegration = value)))} checked={checked} placeholder="How strong are details integrated in the design?" />
                </div>
            </div>
            <div />
            <div className="qualities__col">
                <div className="qualities__col-title">TONES</div>
                <div className="qualities__form">
                    <SelectForm options={paletteDiversity} state={form.paletteDiversity} setState={value => setForm(produce(form, form => void (form.paletteDiversity = value)))} checked={checked} placeholder="How diverse is the design color palette?" />
                    <SelectForm options={paletteTemperature} state={form.paletteTemperature} setState={value => setForm(produce(form, form => void (form.paletteTemperature = value)))} checked={checked} placeholder="Choose design palette temperature" />
                    <ColorForm state={form.primaryTone} setState={value => setForm(produce(form, form => void (form.primaryTone = value)))} checked={checked} label="Select Primary tone" />
                    <ColorForm state={form.secondaryTone} setState={value => setForm(produce(form, form => void (form.secondaryTone = value)))} checked={checked} label="Select Secondary tone" />
                </div>
            </div>
        </div>
    );
}

import { useCallback, useEffect, useMemo, useState } from 'react';
import { useStore } from 'effector-react';
import classNames from 'classnames';
import { ReactComponent as IconSVG } from './icons/icon.svg';
import { ReactComponent as NoteSVG } from './icons/note.svg';
import { ReactComponent as CrossSVG } from './icons/cross.svg';
import Hint from 'Components/MyHint';
import GeneralizedMaterialQualities from './GeneralizedMaterialQualities';
import EnvironmentAndTime from './EnvironmentAndTime';
import { $renderSettings, clearRenderSettings, RenderSettings as RenderSettingsValueType } from 'entities/render';
import { LeftSidePanel, setLeftSidePanel } from 'entities/leftSidePanel';
import { RightSide, setRightSide } from 'entities/router';
import './RenderingSettings.scss';


enum MenuPoint {
    GENERALIZED_MATERIAL_QUALITIES = 'GENERALIZED_MATERIAL_QUALITIES',
    ENVIRONMENT_AND_TIME = 'ENVIRONMENT_AND_TIME',
}

export default function RenderingSettings() {
    const [menuPoint, setMenuPoint] = useState(MenuPoint.GENERALIZED_MATERIAL_QUALITIES);
    const [checked, setChecked] = useState(false);
    const renderSettings = useStore($renderSettings);

    const checkGeneralizedMaterialQualities = useCallback(() => {
        const keys: Array<keyof RenderSettingsValueType> = ['primaryTone', 'secondaryTone', 'colorMode', 'paletteTemperature', 'materialDiversity', 'materialTypePreference', 'textureDominance', 'surfaceFinish', 'detailIntegration'];
        return !keys.some(key => !renderSettings[key]);
    }, [renderSettings]);

    const checkEnvironmentAndTime = useCallback(() => {
        const keys: Array<keyof RenderSettingsValueType> = ['time', 'season', 'weather', 'scenery', 'country'];
        return !keys.some(key => !renderSettings[key]);
    }, [renderSettings]);

    const render = useCallback(() => {
        setChecked(true);
        if (checkGeneralizedMaterialQualities() && checkEnvironmentAndTime()) setLeftSidePanel(LeftSidePanel.SUBMIT_FOR_RENDERING);
    }, [checkGeneralizedMaterialQualities, checkEnvironmentAndTime]);

    const prerender = useCallback(() => {
        setChecked(true);
        if (checkGeneralizedMaterialQualities() && checkEnvironmentAndTime()) console.log('prerender');
    }, [checkGeneralizedMaterialQualities, checkEnvironmentAndTime]);

    const generalizedMaterialQualitiesErrored = useMemo(() => {
        if (!checked) return false;
        return !checkGeneralizedMaterialQualities();
    }, [checked, checkGeneralizedMaterialQualities]);

    const environmentAndTimeErrored = useMemo(() => {
        if (!checked) return false;
        return !checkEnvironmentAndTime();
    }, [checked, checkEnvironmentAndTime]);

    useEffect(clearRenderSettings, []);

    return (
        <div className="rendering-settings">
            <div className="rendering-settings__beta-line">
                <div className="rendering-settings__beta">BETA</div>
                <div className="rendering-settings__beta-text">feature: include files & URLs.</div>
            </div>
            <div className="rendering-settings__head-container">
                <div className="rendering-settings__head">
                    <IconSVG />
                    <div className="rendering-settings__head-text-container">
                        <div className="rendering-settings__head-title">RENDERING SETTINGS</div>
                        <div className="rendering-settings__head-text">
                            <div>Set a generalized vision of your design for AI.</div>
                            <Hint><NoteSVG className="pointer" /></Hint>
                        </div>
                    </div>
                </div>
                <div className="rendering-settings__head-close-button pointer" onClick={() => setRightSide(RightSide.LIST)}>
                    <div className="rendering-settings__head-close-button-text">CLOSE</div>
                    <CrossSVG />
                </div>
            </div>
            <div className="rendering-settings__menu">
                <div
                    className={classNames({
                        'rendering-settings__menu-point': true,
                        'rendering-settings__menu-point_selected': menuPoint === MenuPoint.GENERALIZED_MATERIAL_QUALITIES,
                        'rendering-settings__menu-point_errored': generalizedMaterialQualitiesErrored,
                    })}
                    onClick={() => setMenuPoint(MenuPoint.GENERALIZED_MATERIAL_QUALITIES)}
                >
                    <span>GENERALIZED MATERIAL QUALITIES</span>
                </div>
                <div className="rendering-settings__menu-gap" />
                <div
                    className={classNames({
                        'rendering-settings__menu-point': true,
                        'rendering-settings__menu-point_selected': menuPoint === MenuPoint.ENVIRONMENT_AND_TIME,
                        'rendering-settings__menu-point_errored': environmentAndTimeErrored,
                    })}
                    onClick={() => setMenuPoint(MenuPoint.ENVIRONMENT_AND_TIME)}
                >
                    <span>ENVIRONMENT AND TIME</span>
                </div>
                <div className="rendering-settings__menu-empty-point" />
            </div>
            {menuPoint === MenuPoint.GENERALIZED_MATERIAL_QUALITIES && <GeneralizedMaterialQualities checked={checked} />}
            {menuPoint === MenuPoint.ENVIRONMENT_AND_TIME && <EnvironmentAndTime checked={checked} />}
            <div className="rendering-settings__buttons">
                <div className="rendering-settings__submit-for-rendering-button" onClick={render}>SUBMIT FOR RENDERING</div>
                <div className="rendering-settings__render-preview-button" onClick={prerender}>RENDER PREVIEW</div>
            </div>
        </div>
    );
}
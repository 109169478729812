import EventEmitter from 'eventemitter3';
import { useEffect, useState } from 'react';
import { useStore } from 'effector-react';
import { $manager, checkPrototypeStatusFx, loadMaskFx, loadSketchFx, makePrototypeFx, recognizeFx } from '.';
import * as utils from './utils';
import * as constants from './constants';
import { FileData } from 'services/library';
import { createSketch, saveSketch, renderSketch, saveMask, Mask } from 'services/sketch';
import { $userId } from 'entities/user';
import config from 'config';
import { errorEvent } from 'entities/error';
import Engine from './Engine';
import { SpriteState as SegmentState } from './Engine/Engine';
import { History } from './History';
import { $sketches } from 'entities/sketches';


export enum AttachmentType {
    CUSTOM = 'CUSTOM',
    MODEL = 'MODEL',
}

/**
** [r, g, b, a];
** 0 - 255
*/
export type Color = [number, number, number, number]; // [r, g, b, a];
export type SegmentData = Omit<typeof Segment extends new (arg: infer T) => any ? T : never, 'engine' | 'width' | 'height' | 'manager' | 'maskSize' | 'attachments'> & { attachments: Array<AttachmentProps> };
export type SegmentDataFromDataBase = Omit<SegmentData, 'size'>;
export type GroupData = ReturnType<Group['toJSON']>;
export type ListData = { id: string, constructor: 'Group' | 'Segment' };
export type AttachmentProps = FileData & {
    type?: AttachmentType;
};

export class Attachment extends EventEmitter {
    readonly id: string = crypto.randomUUID();
    readonly originalname: string;
    readonly filename: string;
    readonly type: AttachmentType;

    #note: string;

    get note() {
        return this.#note;
    }
    set note(value: string) {
        this.#note = value;
        this.emit('note', this.note);
    }

    constructor({ originalname, filename, note, type = AttachmentType.CUSTOM }: AttachmentProps) {
        super();
        this.originalname = originalname;
        this.filename = filename;
        this.#note = note;
        this.type = type;
    }

    public getImageSrc(userId: string): string {
        switch (this.type) {
            case AttachmentType.CUSTOM:
                return `${config.minioUrl}/${config.backet}/${userId}/${this.filename}`;
            case AttachmentType.MODEL:
                return `${config.trainerUrl}/preview-images/${encodeURIComponent(this.originalname)}`;
            default:
                return '';
        }
    }

    public sync(payload: ReturnType<typeof this['toJSON']>) {
        if (this.#note !== payload.note) this.note = payload.note;
    }

    public toJSON(): AttachmentProps {
        return {
            originalname: this.originalname,
            filename: this.filename,
            note: this.#note,
            type: this.type,
        };
    }
}

export enum ActionType {
    POSITIVE_BOX = 'POSITIVE_BOX',
    NEGATIVE_BOX = 'NEGATIVE_BOX',
    POSITIVE_POINT = 'POSITIVE_POINT',
    NEGATIVE_POINT = 'NEGATIVE_POINT',
    BRUSH = 'BRUSH',
}

interface Action {
    value: any;
    type: ActionType;
}

export interface Box extends Action {
    value: Array<number>;
    type: ActionType.POSITIVE_BOX | ActionType.NEGATIVE_BOX;
}

export interface Point extends Action {
    value: {
        x: number;
        y: number;
        clickType: 1;
    };
    type: ActionType.POSITIVE_POINT | ActionType.NEGATIVE_POINT;
}

export class Segment extends EventEmitter {
    private readonly engine: Engine;
    private readonly manager: Manager;
    readonly id: string;
    readonly mask: Uint8Array;

    #description: string;
    #attachments: Array<Attachment>;
    #color: Color;
    #blackAndWhiteColor: Color;
    #name: number;
    #state: SegmentState = SegmentState.REGULAR;

    texture: WebGLTexture | null = null;
    blackAndWhite = false;
    hoverOutlineWidth = 0;
    selectOutlineWidth = 0;
    maskBox = [0, 0, 1, 1];
    maskSize = 0;
    width = 0;
    height = 0;
    groupId: string | null;

    constructor({
        id,
        description = '',
        attachments,
        color,
        name,
        width,
        height,
        groupId,
        engine,
        manager,
    }: {
        id: Segment['id'];
        description: Segment['description'];
        attachments: Array<AttachmentProps>;
        color: Segment['color'];
        name: Segment['name'];
        width: Segment['width'];
        height: Segment['height'];
        groupId: Segment['groupId'],
        engine: Segment['engine'],
        manager: Segment['manager'];
    }) {
        super();
        this.id = id;
        this.#description = description;
        this.#attachments = attachments.map(data => new Attachment(data));
        this.#color = color;
        this.#blackAndWhiteColor = [color[0] * 0.299, color[1] * 0.587, color[2] * 0.114, color[3]];
        this.#name = name;
        this.width = width;
        this.height = height;
        this.maskBox = [0, 0, width, height];
        const side = Math.ceil(Math.sqrt(this.width * this.height / 32));
        this.mask = new Uint8Array(side * side * 4);
        this.groupId = groupId;
        this.engine = engine;
        this.manager = manager;
    }

    get description() {
        return this.#description;
    }
    set description(value: string) {
        this.#description = value;
        this.emit('description', this.description);
    }

    get attachments(): Array<Attachment> {
        return this.#attachments;
    }
    set attachments(value: Array<FileData>) {
        this.#attachments = value.map(data => new Attachment(data));
        this.emit('attachments', this.attachments);
    }

    get color() {
        return this.#color;
    }
    set color(value: Color) {
        this.#color = value;
        const brightness = Math.floor(value[0] * 0.299 + value[1] * 0.587 + value[2] * 0.114);
        this.#blackAndWhiteColor = [brightness, brightness, brightness, value[3]];
        this.emit('color', this.color);
    }

    get renderColor() {
        if (this.blackAndWhite) return this.#blackAndWhiteColor;
        else return this.#color;
    }

    get name() {
        return this.#name;
    }
    set name(value: number) {
        this.#name = value;
        this.emit('name', this.name);
    }

    get state() {
        return this.#state;
    }
    set state(value: SegmentState) {
        this.#state = value;
        this.emit('state', this.state);
    }

    public attach(data: AttachmentProps) {
        const attachment = new Attachment(data)
        this.attachments = [...this.#attachments, attachment];
        this.manager.saveSketch();
    }

    public unattach(attachment: Attachment) {
        this.attachments = this.#attachments.filter(a => a !== attachment);
        this.manager.saveSketch();
    }

    public async setSrc(src: string) {
        const image = new Image();
        image.src = src;
        image.crossOrigin = '*';
        await image.decode();

        this.width = image.naturalWidth;
        this.height = image.naturalHeight;
        const { context } = utils.createCanvasAndContext(this.width, this.height);
        context.drawImage(image, 0, 0);
        const buffer = context.getImageData(0, 0, this.width, this.height).data;
        for (let h = 0; h < this.height; h++) {
            for (let w = 0; w < this.width; w++) {
                const index = h * this.width + w;
                const value = Boolean(buffer[(index) * 4 + 3]);

                if (value) {
                    const byteIndex = Math.floor(index / 8);
                    const bitIndex = index % 8;
                    this.maskSize++;
                    this.mask[byteIndex] |= (1 << bitIndex);
                }
            }
        }

        this.updateBoundingRectangle();
    }

    public overMask(x: number, y: number): boolean {
        const index = y * this.width + x;
        const byteIndex = Math.floor(index / 8);
        const bitIndex = index % 8;

        return (this.mask[byteIndex] & (1 << bitIndex)) !== 0;
    }

    public updateMask(mask: Uint8Array) {
        this.mask.set(mask);
        this.engine.updateSprite(this);
        this.updateBoundingRectangle();
        this.updateMaskSize();
    }

    public async getPng(): Promise<Blob> {
        const { canvas, context } = utils.createCanvasAndContext(this.width, this.height);
        const imageData = context.createImageData(this.width, this.height);
        const data = imageData.data;

        const length = this.width * this.height;

        for (let i = 0; i < length; i++) {
            const byteIndex = Math.floor(i / 8);
            const bitIndex = i % 8;
            const isPixelSet = (this.mask[byteIndex] >> bitIndex) & 1;

            const pixelIndex = i * 4;
            if (isPixelSet) {
                data[pixelIndex] = this.color[0];
                data[pixelIndex + 1] = this.color[1];
                data[pixelIndex + 2] = this.color[2];
                data[pixelIndex + 3] = this.color[3];
            }
        }

        context.putImageData(imageData, 0, 0);
        const blob = await new Promise<Blob | null>(res => canvas.toBlob(res, 'image/png'));
        if (!blob) throw new Error('Blob is null.');

        return blob;
    }

    private updateMaskSize() {
        this.maskSize = 0;
        for (let h = 0; h < this.height; h++) {
            for (let w = 0; w < this.width; w++) {
                const index = h * this.width + w;
                const byteIndex = Math.floor(index / 8);
                const value = Boolean(this.mask[byteIndex]);

                if (value) {
                    const bitIndex = index % 8;
                    const isPixelSet = (this.mask[byteIndex] >> bitIndex) & 1;
                    if (isPixelSet) this.maskSize++;
                }
            }
        }
    }

    public async saveMask() {
        const blob = await this.getPng();

        saveMask(blob, this.name, this.manager.id);
    }

    public updateBoundingRectangle() {
        let y1 = null,
            y2 = null,
            x1 = null,
            x2 = null;

        // Находим верхнюю границу (y1)
        for (let i = 0; i < this.height; i++) {
            for (let j = 0; j < this.width; j++) {
                const index = i * this.width + j;
                const byteIndex = Math.floor(index / 8);
                const bitIndex = index % 8;
                if ((this.mask[byteIndex] & (1 << bitIndex)) === 1) {
                    y1 = i;
                    break;
                }
            }
            if (y1 !== null) break;
        }
        if (y1 === null) return [0, 0, 0, 0];

        // Находим нижнюю границу (y2)
        for (let i = this.height - 1; i >= 0; i--) {
            for (let j = 0; j <= this.width; j++) {
                const index = i * this.width + j;
                const byteIndex = Math.floor(index / 8);
                const bitIndex = index % 8;
                if ((this.mask[byteIndex] & (1 << bitIndex)) === 1) {
                    y2 = i + 1;
                    break;
                }
            }
            if (y2 !== null) break;
        }
        if (y2 === null) return [0, 0, 0, 0];

        // Находим левую границу (x1)
        for (let j = 0; j < this.width; j++) {
            for (let i = y1; i <= y2; i++) {
                const index = i * this.width + j;
                const byteIndex = Math.floor(index / 8);
                const bitIndex = index % 8;
                if ((this.mask[byteIndex] & (1 << bitIndex)) === 1) {
                    x1 = Math.max(j - (j % 8 || 8), 0);
                    break;
                }
            }
            if (x1 !== null) break;
        }
        if (x1 === null) return [0, 0, 0, 0];

        // Находим правую границу (x2)
        for (let j = this.width; j >= 0; j--) {
            for (let i = y1; i <= y2; i++) {
                const index = i * this.width + j;
                const byteIndex = Math.floor(index / 8);
                const bitIndex = index % 8;
                if ((this.mask[byteIndex] & (1 << bitIndex)) === 1) {
                    x2 = Math.min(j + (8 - j % 8), this.width);
                    break;
                }
            }
            if (x2 !== null) break;
        }
        if (x2 === null) return [0, 0, 0, 0];


        this.maskBox = this.manager.segmentEditor.segment === this
            ?
            [0, 0, this.width, this.height]
            :
            [x1, y1, x2, y2];
    }

    public sync(payload: SegmentData & { mask: Uint8Array }) {
        if (this.#description !== payload.description) this.description = payload.description;
        this.attachments = payload.attachments.map(data => new Attachment(data));
        if (!this.#color.every((el, index) => el === payload.color[index])) this.color = payload.color;
        if (this.#name !== payload.name) this.name = payload.name;
        this.groupId = payload.groupId;
        if (this.mask !== payload.mask) this.updateMask(payload.mask);
    }

    public toJSON(): SegmentData {
        return {
            description: this.#description,
            attachments: this.#attachments.map(attachment => attachment.toJSON()),
            color: this.color,
            name: this.name,
            id: this.id,
            groupId: this.groupId,
        };
    }
}

type OnnxState = {
    busy: boolean;
    prevTime: number;
};

class SegmentEditor extends EventEmitter {
    private readonly engine: Engine;
    private readonly workerLoading: Promise<void>;
    private readonly recognizingQueue: Array<Parameters<SegmentEditor['onnxUpdate']>> = [];
    private readonly worker: Worker;
    private readonly manager: Manager;
    private sourceMask: Uint8Array;
    private tempMask: Uint8Array;
    private recognizing = false;
    private prevBrushPoint: { x: number; y: number } | null = null;
    private originalColor: Color = [0, 0, 0, 0];

    public brushAlfa: 1 | 0 = 1;

    #segment: Segment | null = null;
    #undo: Array<Uint8Array> = [];
    #redo: Array<Uint8Array> = [];
    #editing = false;
    #onnxState: OnnxState = { busy: false, prevTime: 0 };

    get segment() {
        return this.#segment;
    }
    private set segment(value: Segment | null) {
        this.#segment = value;
        this.emit('segment', this.segment);
    }

    get undo() {
        return this.#undo;
    }
    private set undo(value: Array<Uint8Array>) {
        this.#undo = value;
        this.emit('undo', this.undo);
    }

    get redo() {
        return this.#redo;
    }
    private set redo(value: Array<Uint8Array>) {
        this.#redo = value;
        this.emit('redo', this.redo);
    }

    get editing() {
        return this.#editing;
    }
    set editing(value: boolean) {
        this.#editing = value;
        this.emit('editing', this.editing);
    }

    get onnxState() {
        return this.#onnxState;
    }
    set onnxState(value: OnnxState) {
        this.#onnxState = value;
        this.emit('onnxState', this.onnxState);
    }

    constructor(manager: Manager, engine: Engine) {
        super();

        const worker = new Worker(new URL('./AIWorker.ts', import.meta.url));
        if (!worker) throw new Error('Worker is null.');
        this.worker = worker;
        this.workerLoading = this.initWorker();

        this.tempMask = new Uint8Array(0);
        this.sourceMask = new Uint8Array(0);

        this.manager = manager;
        this.engine = engine;
    }

    public async init(id: string) {
        await Promise.all([
            this.workerLoading,
            this.loadEmbedding(id),
        ]);

        const side = Math.ceil(Math.sqrt(this.manager.width * this.manager.height / 32));
        this.tempMask = new Uint8Array(side * side * 4);
        this.sourceMask = new Uint8Array(side * side * 4);
    }

    private async initWorker() {
        await new Promise((res, rej) => {
            const fn = (event: MessageEvent<any>) => {
                const data = event.data;
                if (data.type !== 'model') return;
                this.worker.removeEventListener('message', fn);
                data.result ? res(null) : rej();
            }

            this.worker.addEventListener('message', fn);
        });
    }

    private async loadEmbedding(id: string) {
        this.worker.postMessage({ type: 'tensor', props: id });
        await new Promise((res, rej) => {
            const fn = (event: MessageEvent<any>) => {
                const data = event.data;
                if (data.type !== 'tensor') return;
                this.worker.removeEventListener('message', fn);
                data.result ? res(null) : rej();
            }

            this.worker.addEventListener('message', fn);
        });
    }

    public editSegment(segment: Segment) {
        this.sourceMask.set(segment.mask);
        this.tempMask.set(segment.mask);
        this.segment = segment;
        this.originalColor = [...this.segment.color];
        this.segment.color = constants.defaultMaskColor;
        this.segment.maskBox = [0, 0, this.manager.width, this.manager.height];
        this.editing = true;
        this.manager.segments.forEach(segment => segment.blackAndWhite = true);
        segment.blackAndWhite = false;
    }

    public cancelCreateSegment() {
        if (!this.segment) throw new Error('Segment is null.');

        this.engine.deleteSprite(this.segment);
        const index = this.manager.segments.findIndex(segment => segment === this.segment);
        if (!~index) throw new Error('Segment not found.');
        this.manager.segments.splice(index, 1);

        this.editing = false;
        this.segment = null;
        this.tempMask.fill(0);
        this.sourceMask.fill(0);
        this.undo = [];
        this.redo = [];
        this.originalColor = [0, 0, 0, 0];
        this.manager.segments.forEach(segment => segment.blackAndWhite = false);
    }

    public cancelEditSegment() {
        if (!this.segment) throw new Error('Segment is null.');

        this.segment.color = [...this.originalColor];
        this.segment.updateMask(this.sourceMask);

        this.editing = false;
        this.segment = null;
        this.tempMask.fill(0);
        this.sourceMask.fill(0);
        this.undo = [];
        this.redo = [];
        this.originalColor = [0, 0, 0, 0];
        this.manager.segments.forEach(segment => segment.blackAndWhite = false);

    }

    public endEditing() {
        if (!this.segment) throw new Error('Segment is null.');

        this.segment.color = [...this.originalColor];
        this.segment.updateMask(this.tempMask);
        this.segment.saveMask();
        this.manager.segments.sort(({ maskSize: a }, { maskSize: b }) => b - a);
        this.manager.segments.forEach(segment => segment.blackAndWhite = false);
        this.manager.saveSketch();

        this.editing = false;
        this.segment = null;
        this.tempMask.fill(0);
        this.sourceMask.fill(0);

        this.undo = [];
        this.redo = [];
        this.originalColor = [0, 0, 0, 0];
    }

    public endCreating() {
        if (!this.segment) throw new Error('Segment is null.');

        this.segment.color = [...this.originalColor];
        this.segment.updateMask(this.tempMask);
        this.segment.saveMask();
        this.segment.color = utils.generateRandomColor();
        this.manager.syncListWithSegments();
        this.manager.segments.sort(({ maskSize: a }, { maskSize: b }) => b - a);
        this.manager.segments.forEach(segment => segment.blackAndWhite = false);
        this.manager.saveSketch();

        this.editing = false;
        this.segment = null;
        this.tempMask.fill(0);
        this.sourceMask.fill(0);

        this.undo = [];
        this.redo = [];
        this.originalColor = [0, 0, 0, 0];
    }

    public async undoAction() {
        if (!this.segment) throw new Error('Segment is null.');
        if (this.#undo.length < 1) return;
        const mask = this.#undo.pop();
        if (!mask) return;
        this.undo = [...this.#undo];
        this.redo = [...this.#redo, this.segment.mask.slice()];
        this.segment.updateMask(mask);
        this.tempMask.set(this.segment.mask);
    }

    public async redoAction() {
        if (!this.segment) throw new Error('Segment is null.');
        const mask = this.#redo.pop();
        if (!mask) return;
        this.undo = [...this.#undo, this.segment.mask.slice()];
        this.redo = [...this.#redo];
        this.segment.updateMask(mask);
        this.tempMask.set(this.segment.mask);
    }

    public async showPoint(x: number, y: number, type: ActionType.POSITIVE_POINT | ActionType.NEGATIVE_POINT) {
        const point: Point = {
            value: {
                ...this.manager.transformCoordinates(x, y),
                clickType: 1,
            },
            type,
        };

        if (point.value.x < 0 || point.value.x > this.manager.width) return;
        if (point.value.y < 0 || point.value.y > this.manager.height) return;

        this.onnx(point, true, false);
    }

    public noPreview() {
        this.onnx('CLEAR', false, false);
    }

    public async addPoint(x: number, y: number, type: ActionType.POSITIVE_POINT | ActionType.NEGATIVE_POINT) {
        const point: Point = {
            value: {
                ...this.manager.transformCoordinates(x, y),
                clickType: 1,
            },
            type,
        };

        if (point.value.x < 0 || point.value.x > this.manager.width) return;
        if (point.value.y < 0 || point.value.y > this.manager.height) return;

        this.onnx(point, true, true);
    }

    public async showBox(box: Array<number>) {
        const x0x1 = [box[0], box[2]].sort((a, b) => a - b);
        const y0y1 = [box[1], box[3]].sort((a, b) => a - b);
        const сoordinates = [x0x1[0], y0y1[0], x0x1[1], y0y1[1]];
        const sortedBox: Box = { value: сoordinates, type: ActionType.POSITIVE_BOX };
        this.engine.frame = sortedBox;

        const { x: x0, y: y0 } = this.manager.transformCoordinates(сoordinates[0], сoordinates[1]);
        const { x: x1, y: y1 } = this.manager.transformCoordinates(сoordinates[2], сoordinates[3]);

        const realBox: Box = { value: [Math.max(x0, 0), Math.max(y0, 0), Math.min(x1, this.manager.width), Math.min(y1, this.manager.height)], type: ActionType.POSITIVE_BOX };

        this.onnx(realBox, true, false);
    }

    public async addBox(box: Array<number>) {
        const x0x1 = [box[0], box[2]].sort((a, b) => a - b);
        const y0y1 = [box[1], box[3]].sort((a, b) => a - b);
        const сoordinates = [x0x1[0], y0y1[0], x0x1[1], y0y1[1]];
        this.engine.frame = null;

        const { x: x0, y: y0 } = this.manager.transformCoordinates(сoordinates[0], сoordinates[1]);
        const { x: x1, y: y1 } = this.manager.transformCoordinates(сoordinates[2], сoordinates[3]);

        const realBox: Box = { value: [Math.max(x0, 0), Math.max(y0, 0), Math.min(x1, this.manager.width), Math.min(y1, this.manager.height)], type: ActionType.POSITIVE_BOX };

        this.onnx(realBox, true, true);
    }

    public hideBox() {
        this.engine.frame = null;
    }

    public draw(x: number, y: number) {
        if (!this.segment) throw new Error('Segment is null.');

        const { x: x2, y: y2 } = this.manager.transformCoordinates(x, y);
        const x1 = Math.round(x2);
        const y1 = Math.round(y2);

        const canvas = this.manager.canvas;
        if (!canvas) throw new Error('Canvas is null.');
        const canvasWidth = canvas.width;
        const canvasScale = canvasWidth / this.manager.width;

        const radius = Math.round(this.engine.cursorRadius / this.engine.scale / 2 / canvasScale);

        const setPixel = (bitmap: Uint8Array, width: number, height: number, x: number, y: number) => {
            if (x >= 0 && x < width && y >= 0 && y < height) {
                const bitIndex = y * width + x;
                const byteIndex = Math.floor(bitIndex / 8);
                const bitPosition = bitIndex % 8;

                if (this.brushAlfa) bitmap[byteIndex] |= (1 << bitPosition);
                else bitmap[byteIndex] &= ~(1 << bitPosition);
            }
        }

        function drawFilledCircle(bitmap: Uint8Array, width: number, height: number, centerX: number, centerY: number, radius: number) {
            for (let y = -radius; y <= radius; y++) {
                for (let x = -radius; x <= radius; x++) {
                    if (x * x + y * y <= radius * radius) {
                        setPixel(bitmap, width, height, centerX + x, centerY + y);
                    }
                }
            }
        }

        let x0 = this.prevBrushPoint ? this.prevBrushPoint.x : x1;
        let y0 = this.prevBrushPoint ? this.prevBrushPoint.y : y1;
        let dx = Math.abs(x1 - x0);
        let dy = Math.abs(y1 - y0);
        let sx = (x0 < x1) ? 1 : -1;
        let sy = (y0 < y1) ? 1 : -1;
        let err = dx - dy;

        while (true) {
            drawFilledCircle(this.segment.mask, this.segment.width, this.segment.height, x0, y0, radius);

            if (x0 === x1 && y0 === y1) break;
            let e2 = 2 * err;
            if (e2 > -dy) {
                err -= dy;
                x0 += sx;
            }
            if (e2 < dx) {
                err += dx;
                y0 += sy;
            }
        }

        this.engine.updateSprite(this.segment);

        this.prevBrushPoint = { x: x1, y: y1 };
    }

    public endDraw() {
        if (!this.segment) throw new Error('Segment is null.');

        this.prevBrushPoint = null;
        this.undo = [...this.#undo, this.tempMask.slice()];
        this.redo = [];
        this.tempMask.set(this.segment.mask);
    }

    private async onnxUpdate(payload: Point | Box | 'CLEAR', join: boolean, permanent: boolean): Promise<void> {
        if (!this.segment) throw new Error('Segment is null.');

        if (payload === 'CLEAR') {
            this.segment.updateMask(this.tempMask);
            this.engine.updateSprite(this.segment);
            return;
        }

        const LONG_SIDE_LENGTH = 1024;
        const samScale = LONG_SIDE_LENGTH / Math.max(this.manager.height, this.manager.width);

        switch (payload.type) {
            case ActionType.POSITIVE_BOX:
                this.worker.postMessage({ type: 'mask', props: { box: payload.value, points: [], height: this.manager.height, width: this.manager.width, samScale } });
                break;
            case ActionType.NEGATIVE_BOX:
                this.worker.postMessage({ type: 'mask', props: { box: payload.value, points: [], height: this.manager.height, width: this.manager.width, samScale } });
                break;
            case ActionType.NEGATIVE_POINT:
                this.worker.postMessage({ type: 'mask', props: { box: [], points: [payload.value], height: this.manager.height, width: this.manager.width, samScale } });
                break;
            case ActionType.POSITIVE_POINT:
                this.worker.postMessage({ type: 'mask', props: { box: [], points: [payload.value], height: this.manager.height, width: this.manager.width, samScale } });
                break;
        }
        this.onnxState = { busy: true, prevTime: this.onnxState.prevTime };
        const start = performance.now();
        const { buffer } = await new Promise<{ buffer: ArrayBufferLike }>((res, rej) => {
            const fn = (event: MessageEvent<any>) => {
                const data = event.data;
                if (data.type !== 'mask') return;
                this.worker.removeEventListener('message', fn);
                data.result ? res(data.result) : rej();
            }

            this.worker.addEventListener('message', fn);
        });
        const end = performance.now();
        const prevTime = end - start;
        this.onnxState = { busy: false, prevTime };

        const array = new Uint8Array(buffer);

        const arrayIsPositive = payload.type.includes('POSITIVE');

        if (join === arrayIsPositive) {
            for (let i = 0; i < array.length; i++) {
                this.segment.mask[i] = this.tempMask[i] | array[i];
            }
        } else {
            for (let i = 0; i < array.length; i++) {
                this.segment.mask[i] = this.tempMask[i] & ~array[i];
            }
        }

        if (permanent) {
            this.undo = [...this.#undo, this.tempMask.slice()];
            this.redo = [];
            this.tempMask.set(this.segment.mask);
        }

        this.engine.updateSprite(this.segment);
    }

    private async onnx(...payload: Parameters<SegmentEditor['onnxUpdate']>): Promise<void> {
        if (this.recognizing) {
            let i = 0;
            while (i < this.recognizingQueue.length) {
                if (this.recognizingQueue[i][2]) i++;
                else this.recognizingQueue.splice(i, 1);
            }

            this.recognizingQueue.push(payload);
        } else {
            this.recognizing = true;
            return new Promise<void>(async res => {
                try {
                    await this.onnxUpdate(...payload);
                } catch (e) {
                    console.error(e);
                }
                res();
                this.recognizing = false;
                const next = this.recognizingQueue.shift();
                if (next) this.onnx(...next);
            });
        }
    }
}

type GroupProps = {
    segments: Array<Segment>;
    description: string;
    attachments: Array<AttachmentProps>;
    id?: string;
    manager: Manager;
}

export class Group extends EventEmitter {
    readonly id: string;
    readonly manager: Manager;
    public creating = false;

    #segments: Array<Segment> = [];
    #description: string;
    #attachments: Array<Attachment>;

    constructor({ segments, description, attachments, id = crypto.randomUUID(), manager }: GroupProps) {
        super();
        this.id = id;
        this.manager = manager;
        this.#segments = segments;
        this.#segments.forEach(segment => segment.groupId = this.id);
        this.#description = description;
        this.#attachments = attachments.map(data => new Attachment(data));
    }

    get segments() {
        return this.#segments;
    }
    private set segments(value: Array<Segment>) {
        this.#segments = value;
        this.emit('segments', this.segments);
    }

    get description() {
        return this.#description;
    }
    set description(value: string) {
        this.#description = value;
        this.emit('description', this.description);
    }

    get attachments(): Array<Attachment> {
        return this.#attachments;
    }
    set attachments(value: Array<FileData>) {
        this.#attachments = value.map(data => new Attachment(data));
        this.emit('attachments', this.attachments);
    }

    public addSegment(segment: Segment, destination: number) {
        /* @ts-ignore */
        this.segments = this.#segments.toSpliced(destination, 0, segment);
        segment.groupId = this.id;
    }

    public changeSegmentPosition(source: number, destination: number) {
        const segments = [...this.#segments];
        const segment = segments.splice(source, 1)[0];
        segments.splice(destination, 0, segment);
        this.segments = segments;
    }

    public removeSegment(segment: Segment) {
        this.segments = this.#segments.filter(s => s !== segment);
        segment.groupId = null;
    }

    public unattach(attachment: FileData) {
        this.attachments = this.#attachments.filter(a => a !== attachment);
        this.manager.saveSketch();
    }

    public attach(attachment: FileData) {
        this.attachments = [...this.#attachments, attachment];
        this.manager.saveSketch();
    }

    public sync(payload: GroupData) {
        if (this.#description !== payload.description) this.description = payload.description;
        this.attachments = payload.attachments.map(data => new Attachment(data));
        if (this.#segments.length !== payload.segments.length || !this.#segments.every(segment => payload.segments.some(id => id === segment.id))) {
            this.segments = payload.segments.map(id => {
                const segment = this.manager.segments.find(segment => segment.id === id);
                if (!segment) throw new Error(`Segment ${id} not found.`);
                return segment;
            });
        }
    }

    public toJSON() {
        return {
            id: this.id,
            segments: this.#segments.map(({ id }) => id),
            description: this.#description,
            attachments: this.#attachments.map(attachment => attachment.toJSON()),
        };
    }
}

type ManagerProps = {
    id: string;
    name: string;
};

export enum ManagerInitStep {
    CREATED,
    INPUT_LOADED,
    PROTOTYPE_LOADED,
    RECOGNIZING,
    SEGMENTED,
    READY,
}

class Initiator extends EventEmitter {
    private readonly manager: Manager;
    private prototypingStatus = 'none';

    #prototyping = false;
    $fileName = '';
    #initStep = ManagerInitStep.CREATED;

    constructor(manager: Manager) {
        super();
        this.manager = manager;
    }

    get prototyping() {
        return this.#prototyping;
    }
    set prototyping(value: boolean) {
        this.#prototyping = value;
        this.emit('prototyping', this.prototyping);
    }

    get fileName() {
        return this.$fileName;
    }
    set fileName(value: string) {
        this.$fileName = value;
        this.emit('fileName', this.fileName);
    }

    get initStep() {
        return this.#initStep;
    }
    set initStep(value: ManagerInitStep) {
        this.#initStep = value;
        this.emit('initStep', this.initStep);
    }

    public async initExistedSketch(id: string) {
        try {
            this.manager.id = id;
            const sketch = await loadSketchFx(this.manager.id);
            this.manager.name = sketch.name;

            this.manager.input.src = `${config.backendUrl}/api/masks/${this.manager.id}/${this.manager.id}.png`;
            this.manager.input.crossOrigin = '*';
            await this.manager.input.decode();
            this.manager.width = this.manager.input.width;
            this.manager.height = this.manager.input.height;
            this.manager.selectBackground('input');
            this.initStep = ManagerInitStep.INPUT_LOADED;

            this.manager.baseRenders = sketch.baseRenders.split(',');
            const baseRender = this.manager.baseRenders.at(-1);
            if (!baseRender) throw new Error('Base renders not found.');
            this.manager.prototype.src = `${config.baseRenderUrl}/output/${baseRender}`;
            this.manager.prototype.crossOrigin = '*';
            await this.manager.prototype.decode();
            this.manager.selectBackground('prototype');
            this.initStep = ManagerInitStep.PROTOTYPE_LOADED;

            const { segments, groups } = JSON.parse(sketch.config);
            this.manager.segments.splice(0, this.manager.segments.length);
            await this.manager.segmentEditor.init(this.manager.id);
            await this.initSegments(segments, config.backendUrl + '/api');
            this.initGroups(groups);
            this.manager.list = [...this.manager.segments];
            this.initStep = ManagerInitStep.READY;
        } catch (e) {
            console.error('Error while loading sketch.');
            console.error(e);
            errorEvent();
        }
    }

    private async initSegments(masks: Array<SegmentDataFromDataBase>, hostName: string) {
        await Promise.all(masks.map(async mask => {
            const segment = new Segment({ ...mask, engine: this.manager.engine, manager: this.manager, width: this.manager.width, height: this.manager.height });
            await segment.setSrc(`${hostName}/masks/${this.manager.id}/${mask.name}.png`);
            this.manager.engine.addSprite(segment);
            segment.color = utils.generateRandomColor();
            this.manager.segments.push(segment);
        }));
        this.manager.segments.sort((a, b) => b.maskSize - a.maskSize);
    }


    private initGroups(groups: Array<GroupData>) {
        this.manager.groups = groups.map(group => {
            const segments = group.segments.map(id => {
                const segment = this.manager.segments.find(segment => segment.id === id);
                if (!segment) throw new Error('The segment for the group was not found.');
                return segment;
            });
            return new Group({ manager: this.manager, ...group, segments });
        });
    }

    private initList(list: Array<ListData>) {
        this.manager.list = list.map(element => {
            if (element.constructor === 'Segment') {
                const segment = this.manager.segments.find(segment => segment.id === element.id);
                if (!segment) throw new Error('The segment for the list was not found.');
                return segment;
            }
            if (element.constructor === 'Group') {
                const group = this.manager.groups.find(group => group.id === element.id);
                if (!group) throw new Error('The group for the list was not found.');
                return group;
            }
            throw new Error('Unknown element type.');
        });
    }

    public async makePrototype(prompt: string) {
        try {
            this.prototyping = true;
            console.log(prompt);

            this.manager.prototype.src = 'data:image/gif;base64,R0lGODlhAQABAAAAACw=';
            this.manager.baseRenders = [];
            this.manager.selectBackground('input');

            const response = await fetch(this.manager.input.src);
            const blob = await response.blob();
            const file = new File([blob], 'control_image.png', { type: 'image/png' });

            const id = await makePrototypeFx({ file, prompt });

            this.manager.baseRenders = [id];
            this.prototypingStatus = 'processing';
            while (this.prototypingStatus === 'processing') {
                await utils.delay(1000);
                const prototypingStatus = await checkPrototypeStatusFx(id);
                if (prototypingStatus === 'errored') throw new Error('Failed to make prototype.');
                if (this.prototypingStatus as any === 'none') return;
                this.prototypingStatus = prototypingStatus;
            }

            this.manager.prototype.src = `${config.baseRenderUrl}/output/${id}`;
            this.manager.prototype.crossOrigin = '*';
            await this.manager.prototype.decode();
            this.manager.selectBackground('prototype');
            this.initStep = ManagerInitStep.PROTOTYPE_LOADED;
            this.prototyping = false;
        } catch (e) {
            console.error('Error while making prototype.');
            console.error(e);
            errorEvent();
        }
    }

    public cancelPrototyping() {
        this.prototypingStatus = 'none';
        this.prototyping = false;
    }

    public async recognize() {
        try {
            this.initStep = ManagerInitStep.RECOGNIZING;

            const response = await fetch(this.manager.prototype.src);
            const blob = await response.blob();
            const file = new File([blob], 'control_image.png', { type: 'image/png' });

            this.manager.id = await recognizeFx(file);
            let recognizingStatus = 'processing';
            let masks: Array<Mask> = [];
            while (recognizingStatus === 'processing') {
                await utils.delay(1000);
                const result = await loadMaskFx(this.manager.id);
                if (result instanceof Array) {
                    masks = result;
                    recognizingStatus = 'done';
                }
            }

            const removedSegments = this.manager.segments.splice(0, this.manager.segments.length);
            removedSegments.forEach(segment => this.manager.engine.deleteSprite(segment));
            const filledMasks: Array<SegmentDataFromDataBase> = masks.map(({ name }) => ({ name, id: crypto.randomUUID(), attachments: [], color: utils.generateRandomColor(), description: '', groupId: null }));
            await this.initSegments(filledMasks, config.serverUrl);
            this.removeIdenticalMasks();
            this.manager.list = [...this.manager.segments];
            await this.manager.createSketch();
            this.initStep = ManagerInitStep.READY;
        } catch (e) {
            console.error('Error while recognizing.');
            console.error(e);
            errorEvent();
        }
    }

    private removeIdenticalMasks() {
        this.manager.segments.forEach((segment, index) => {
            let removedCount = 0;
            for (let i = index + 1; i < this.manager.segments.length; i++) {
                let intersectionCount = 0;

                for (let j = 0; j < segment.mask.length; j++) {
                    let and = segment.mask[j] & this.manager.segments[i - removedCount].mask[j];

                    while (and) {
                        intersectionCount += and & 1;
                        and >>= 1;
                    }
                }

                const intersectionPercentage = intersectionCount / segment.maskSize * 100;
                if (intersectionPercentage > 90) {
                    this.manager.removeSegment(this.manager.segments[i - removedCount]);
                    removedCount++;
                }
            }
        });
    }

    public initBlank() {
        const sketches = $sketches.getState();
        this.manager.name = constants.demoSketch.name;
        const untitledNames = sketches
            .map(sketch => sketch.name)
            .filter(name => name.startsWith('Untitled'));

        if (untitledNames.length) {
            const numbers = untitledNames
                .map(name => {
                    const match = name.match(/^Untitled (\d+)$/);
                    return match ? parseInt(match[1], 10) : 0;
                })
                .filter(num => num > 0);
            const maxNumber = numbers.length > 0 ? Math.max(...numbers) : 0;

            this.manager.name = `Untitled ${maxNumber + 1}`;
        }

        Promise.all(constants.demoSketch.segments.map(async mask => {
            const segment = new Segment({ ...mask, engine: this.manager.engine, manager: this.manager, width: this.manager.width, height: this.manager.height });
            segment.color = utils.generateRandomColor();
            this.manager.segments.push(segment);
        }));

        this.initGroups(constants.demoSketch.groups);
        this.initList(constants.demoSketch.list);
    }

    public async applySource(file: File) {
        try {
            this.initStep = ManagerInitStep.CREATED;
            this.manager.prototype.src = 'data:image/gif;base64,R0lGODlhAQABAAAAACw=';
            this.manager.baseRenders = [];
            if (this.manager.input.src) this.manager.selectBackground('input');

            this.fileName = file.name;
            const reader = new FileReader();
            reader.onload = async e => {
                const target = e.target;
                if (!target) throw new Error('Target is null');
                const result = target.result;
                if (typeof result !== 'string') throw new Error('Result is not string.');
                const image = new Image();
                image.src = result;
                await image.decode();

                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');

                if (!ctx) throw new Error('Context is not exist.');

                if (image.width > 1024 || image.height > 1024) {
                    const widthRatio = 1024 / image.width;
                    const heightRatio = 1024 / image.height;
                    const scale = Math.min(widthRatio, heightRatio);

                    canvas.width = Math.floor(image.width * scale);
                    canvas.height = Math.floor(image.height * scale);
                } else {
                    canvas.width = image.width;
                    canvas.height = image.height;
                }

                this.manager.width = canvas.width;
                this.manager.height = canvas.height;

                ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

                const dataURL = canvas.toDataURL('image/png');

                this.manager.input.src = dataURL;
                await this.manager.input.decode();
                this.manager.engine.updatePicture(this.manager.input);
                this.initStep = ManagerInitStep.INPUT_LOADED;
            };
            reader.readAsDataURL(file);
        } catch (e) {
            console.error('Error while applying source.');
            console.error(e);
            errorEvent();
        }
    }
}

export class Manager extends EventEmitter {
    private readonly hoverOutlineWidth = 5;
    private readonly selectOutlineWidth = 3;
    public readonly initiator = new Initiator(this);
    public readonly engine: Engine;
    public readonly history: History;
    public readonly segments: Array<Segment> = [];  // READONLY!!!
    public readonly segmentEditor: SegmentEditor;
    public readonly input = new Image();
    public readonly prototype = new Image();

    firstLoad = false;
    canvas: HTMLCanvasElement | null = null;
    context: WebGL2RenderingContext | null = null;
    width = 0;
    height = 0;

    #groups: Array<Group> = [];
    #list: Array<Segment | Group> = [];
    #selectedElements: Array<Segment | Group> = [];
    #matchDefinition: { element: Segment | Group; full: boolean; x: number; y: number } | null = null;
    #id: string;
    #baseRenders: Array<string> = [];
    #selectedBackground: null | 'input' | 'prototype' = null;
    #editingGroup: Group | undefined;
    #hoveredElements: Array<Segment | Group> = [];
    #name: string;
    #deltaFrame: number | null = null;

    constructor({ id, name }: ManagerProps) {
        super();
        this.#id = id;
        this.#name = name;
        this.engine = new Engine(this.segments);
        this.history = new History(this);
        this.segmentEditor = new SegmentEditor(this, this.engine);
    }

    get id() {
        return this.#id;
    }
    set id(value: string) {
        this.#id = value;
    }

    get baseRenders() {
        return this.#baseRenders;
    }
    set baseRenders(value: Array<string>) {
        this.#baseRenders = value;
        this.emit('baseRenders', this.baseRenders);
    }

    get selectedBackground() {
        return this.#selectedBackground;
    }
    set selectedBackground(value: null | 'input' | 'prototype') {
        this.#selectedBackground = value;
        this.emit('selectedBackground', this.selectedBackground);
    }

    get editingGroup() {
        return this.#editingGroup;
    }
    set editingGroup(value: Group | undefined) {
        if (value) this.segmentEditor.editing = false;
        this.#editingGroup = value;
        this.emit('editingGroup', this.editingGroup);
    }

    get hoveredElements() {
        return this.#hoveredElements;
    }
    set hoveredElements(value: Array<Segment | Group>) {
        this.#hoveredElements = value;
        this.segments.forEach(segment => segment.hoverOutlineWidth = 0);
        value.forEach(element => {
            if (element instanceof Segment) element.hoverOutlineWidth = this.hoverOutlineWidth;
            if (element instanceof Group) element.segments.forEach(segment => segment.hoverOutlineWidth = this.hoverOutlineWidth);
        });
        this.emit('hoveredElements', this.hoveredElements);
    }

    get groups() {
        return this.#groups
    }
    set groups(value: Array<Group>) {
        this.#groups = value;
        this.emit('groups', this.groups);
    }

    get list() {
        return this.#list;
    }
    set list(value: Array<Segment | Group>) {
        this.#list = value;
        this.emit('list', this.list);
    }

    get selectedElements() {
        return this.#selectedElements;
    }
    private set selectedElements(value: Array<Segment | Group>) {
        this.#selectedElements = value;
        this.segments.forEach(segment => segment.selectOutlineWidth = 0);
        value.forEach(element => {
            if (element instanceof Segment) element.selectOutlineWidth = this.selectOutlineWidth;
            if (element instanceof Group) element.segments.forEach(segment => segment.selectOutlineWidth = this.selectOutlineWidth);
        })
        this.emit('selectedElements', this.selectedElements);
    }

    get matchDefinition() {
        return this.#matchDefinition;
    }
    set matchDefinition(value: { element: Segment | Group; full: boolean; x: number; y: number } | null) {
        this.#matchDefinition = value;
        this.emit('matchDefinition', this.matchDefinition);
    }

    get name() {
        return this.#name;
    }
    set name(value: string) {
        this.#name = value;
        this.emit('name', this.name);
    }

    get deltaFrame() {
        return this.#deltaFrame;
    }
    set deltaFrame(value: number | null) {
        this.#deltaFrame = value;
        if (value !== null) {
            if (value >= 0) {
                this.engine.frameColor = [0.15, 0.39, 1, 1];
                this.engine.frameDashed = false;
            }
            else {
                this.engine.frameColor = [0.15, 0.39, 1, 1];
                this.engine.frameDashed = true;
            }
        }
        this.emit('deltaFrame', this.deltaFrame);
    }

    get self() {
        return this;
    }

    public createNewSegment(id: string = crypto.randomUUID()): Segment {
        const segment = new Segment({
            id,
            description: '',
            attachments: [],
            color: utils.generateRandomColor(),
            name: -1,
            width: this.width,
            height: this.height,
            groupId: null,
            engine: this.engine,
            manager: this,
        });
        /* @ts-ignore */
        segment.name = this.list.toSorted(({ name: a }, { name: b }) => a - b).slice(-1)[0].name + 1;

        this.segments.push(segment);
        this.engine.addSprite(segment);

        return segment;
    }

    public selectElement(value: Segment | Group) {
        this.selectedElements = [...this.#selectedElements, value];
    }

    public selectOneElement(value: Segment | Group) {
        this.selectedElements = [value];
    }

    public selectElements(value: Array<Segment | Group>) {
        this.selectedElements = value;
    }

    public unselectElement(value: Segment | Group) {
        this.selectedElements = this.#selectedElements.filter(segment => segment !== value);
    }

    public unselectAllElements() {
        this.selectedElements = [];
    }

    public changeOrder(source: number, destination: number) {
        const list = [...this.#list];
        const element = list.splice(source, 1)[0];
        list.splice(destination, 0, element);
        this.list = list;
    }

    public addSegmentToGroup(segment: Segment, group: Group, destination: number) {
        const alreadyInGroup = this.groups.flatMap(({ segments }) => segments).includes(segment);
        if (alreadyInGroup) return;
        group.addSegment(segment, destination);
        this.list = this.#list.filter(s => s !== segment);
    }

    public removeSegmentFromGroup(segment: Segment, group: Group, destination: number) {
        group.removeSegment(segment);
        /* @ts-ignore */
        this.list = this.#list.toSpliced(destination, 0, segment);
    }

    public connectCanvas(canvas: HTMLCanvasElement) {
        this.canvas = canvas;
        const context = canvas.getContext('webgl2', { preserveDrawingBuffer: true });
        if (!context) throw new Error('Context not found.');
        this.context = context;
        const fn = () => {
            this.engine.draw();
            requestAnimationFrame(fn);
        }
        this.engine.init(context).then(() => {
            this.engine.draw();
            requestAnimationFrame(fn);
        });
        const resizeObserver = new ResizeObserver(() => context.viewport(0, 0, canvas.width, canvas.height));
        resizeObserver.observe(canvas);
    }

    public syncListWithSegments() {
        this.segments.forEach(segment => {
            if (!this.list.includes(segment)) this.list = [...this.#list, segment];
        });
    }

    public getSketchData() {
        const list = this.#list.map((element): ListData => {
            if (element instanceof Segment) return ({ id: element.id, constructor: 'Segment' });
            if (element instanceof Group) return ({ id: element.id, constructor: 'Group' });
            throw new Error('Element is uknown.');
        });

        return { id: this.id, list, segments: this.segments, groups: this.#groups, userId: $userId.getState(), name: this.#name, baseRenders: this.#baseRenders.join(',') };
    }

    public createGroup(segments: Array<Segment>) {
        const list = [...this.#list];
        const indexes = this.list.reduce<Array<number>>((acc, cur, index) => {
            if (!segments.includes(cur as any)) return acc;
            acc.push(index);
            return acc;
        }, []).sort((a, b) => a - b);
        this.unselectAllElements();
        const group = new Group({ manager: this, segments, description: '', attachments: [] });
        group.creating = true;
        /* @ts-ignore */
        indexes.toReversed().forEach(index => list.splice(index, 1));
        list.splice(indexes[0], 0, group);
        this.groups = [...this.#groups, group];
        this.list = list;
        setTimeout(() => {
            const element = document.getElementById('element_' + group.id);
            element?.scrollIntoView({ block: 'center' });
        }, 10);
        return group;
    }

    public changeToRandomColor(segment: Segment) {
        segment.color = utils.generateRandomColor();
        this.saveSketch();
    }

    public ungroup(groups: Array<Group>) {
        groups.forEach(group => {
            if (this.#editingGroup === group) this.doneEditingGroup();
            const list = [...this.#list];
            const index = list.indexOf(group);
            list.splice(index, 1, ...group.segments);
            this.groups = this.#groups.filter(g => g !== group);
            this.list = list;
            group.segments.forEach(segment => segment.groupId = null);
        });
        this.saveSketch();
    }

    public removeElements(elements: Array<Segment | Group>) {
        elements.forEach(element => {
            if (element instanceof Segment) this.removeSegment(element);
            if (element instanceof Group) this.removeGroup(element);
        });
        this.saveSketch();
    }

    public removeSegments(segments: Array<Segment>, fromHistory: boolean = false) {
        segments.forEach(segment => this.removeSegment(segment));
        if (!fromHistory) this.saveSketch();
    }

    public removeSegment(segment: Segment) {
        if (this.#hoveredElements.includes(segment)) this.hoveredElements = this.#hoveredElements.filter(element => element !== segment);
        this.selectedElements = this.#selectedElements.filter(element => element !== segment);
        this.list = this.#list.filter(s => s !== segment);
        const index = this.segments.findIndex(s => s === segment);
        if (!~index) throw new Error('Segment not found.');
        this.segments.splice(index, 1);
        this.groups.forEach(group => {
            if (group.segments.includes(segment)) group.removeSegment(segment);
        });
        this.engine.deleteSprite(segment);
    }

    public doneEditingGroup() {
        const group = this.editingGroup;
        if (!group) throw new Error('Group is not editing.');
        group.creating = false;
        this.editingGroup = undefined;
        this.saveSketch();
    }

    public cancelEditingGroup() {
        const group = this.editingGroup;
        if (!group) throw new Error('Group is not editing.');
        if (group.creating) this.ungroup([group]);
        else this.saveSketch();
        this.editingGroup = undefined;
    }

    public undefine(elements: Array<Segment | Group>) {
        elements.forEach(element => {
            element.description = '';
            element.attachments = [];
        });
        this.saveSketch();
    }

    public setSelectBox(value: Box['value'] | null): void {
        this.engine.frame = value && {
            value,
            type: ActionType.POSITIVE_BOX,
        };
    }

    public selectSegmentsWithBox(hover: boolean, shift: boolean, ctrl: boolean) {
        if (!this.engine.frame) throw new Error('Select box is not exist.');
        const { x: x0, y: y0 } = this.transformCoordinates(this.engine.frame.value[0], this.engine.frame.value[1]);
        const { x: x1, y: y1 } = this.transformCoordinates(this.engine.frame.value[2], this.engine.frame.value[3]);
        const selectBox = [x0, y0, x1, y1].map(Math.floor);
        const sortedBox = [
            selectBox[0] < selectBox[2] ? selectBox[0] : selectBox[2],
            selectBox[1] < selectBox[3] ? selectBox[1] : selectBox[3],
            selectBox[0] < selectBox[2] ? selectBox[2] : selectBox[0],
            selectBox[1] < selectBox[3] ? selectBox[3] : selectBox[1],
        ];
        const width = this.width;
        const height = this.height;
        const everyone = selectBox[0] > selectBox[2];
        const rectMask = new Uint8Array(width * height / 8);

        for (let h = sortedBox[1]; h <= sortedBox[3]; h++) {
            for (let w = sortedBox[0]; w <= sortedBox[2]; w++) {
                const index = h * width + w;
                const byteIndex = Math.floor(index / 8);
                const bitIndex = index % 8;

                rectMask[byteIndex] |= (1 << bitIndex);
            }
        }

        const intersected = this.segments.filter(segment =>
            !(segment.maskBox[0] > sortedBox[2] ||
                segment.maskBox[2] < sortedBox[0] ||
                segment.maskBox[1] > sortedBox[3] ||
                segment.maskBox[3] < sortedBox[1])
        ).filter(segment => {
            let intersection = 0;
            for (let i = 0; i < rectMask.length; i++) {
                let and = rectMask[i] & segment.mask[i];

                while (and) {
                    intersection += and & 1;
                    and >>= 1;
                }
            }

            if (everyone) return intersection / segment.maskSize > 0;
            else return intersection / segment.maskSize === 1;
        });

        if (hover) this.hoveredElements = intersected;
        else {
            if (shift) return intersected.forEach(segment => this.unselectElement(segment));
            if (ctrl) return intersected.forEach(segment => this.selectElement(segment));
            return this.selectElements(intersected);
        }
    }

    public removeGroup(group: Group) {
        this.list = this.#list.filter(g => g !== group);
        group.segments.forEach(segment => this.removeSegment(segment));
        this.groups = this.#groups.filter(g => g !== group);
        this.saveSketch();
    }

    public selectBackground(option: 'input' | 'prototype') {
        switch (option) {
            case 'input':
                this.engine.updatePicture(this.input);
                this.selectedBackground = 'input';
                return;
            case 'prototype':
                this.engine.updatePicture(this.prototype);
                this.selectedBackground = 'prototype';
                return;
        }
    }

    public async createSketch() {
        const data = JSON.stringify(this.getSketchData());

        const masks: Parameters<typeof createSketch>[1] = [];

        await Promise.all(this.segments.map(async segment => {
            const mask = await segment.getPng();

            masks.push({ mask, name: segment.name });
        }));

        const response = await fetch(this.input.src);
        const blob = await response.blob();
        const file = new File([blob], this.id + '.png', { type: 'image/png' });

        await createSketch(data, masks, file, this.id);
    }

    public async saveSketch() {
        this.history.create();
        const data = JSON.stringify(this.getSketchData());

        await utils.delay(0);
        const sketch = await this.engine.drawPreview();

        await saveSketch(data, sketch, this.#id);
    }

    public async renderSketch(settings: string) {
        await this.saveSketch();

        const sketch = await this.engine.drawPreview();

        const masks: Parameters<typeof renderSketch>[1] = [];

        await Promise.all(this.segments.map(async segment => {
            const color = segment.color;
            segment.color = [0, 160, 80, 255];
            const mask = await segment.getPng();
            segment.color = color;

            masks.push({ mask, id: segment.id });
        }));

        const data = JSON.stringify(this.getSketchData());

        await renderSketch(sketch, masks, data, settings, crypto.randomUUID());
    }

    public transformCoordinates(x: number, y: number) {
        if (!this.canvas) throw new Error('Canvas is null.');
        const position = this.engine.calcGlobalPosition();
        const scale = this.engine.calcScaleMatrix2();

        return {
            x: (x - (1 + position[0]) * this.canvas.width / 2) * this.width / this.canvas.width / scale[0],
            y: (y - (1 - position[3]) * this.canvas.height / 2) * this.height / this.canvas.height / scale[1],
        };
    }

    public hoverSegment = this._hoverSegment();

    private _hoverSegment() {
        let prevSegments: Array<Segment> = [];
        return (rawX: number, rawY: number) => {
            const { x, y } = this.transformCoordinates(rawX, rawY);
            if (x < 0 || x > this.width) return this.hoveredElements = [];
            if (y < 0 || y > this.height) return this.hoveredElements = [];
            const segments = this.segments.filter((segment: Segment) => segment.overMask(Math.floor(x), Math.floor(y)));
            if (prevSegments.length === segments.length) {
                const diff = segments.filter(segment => !prevSegments.includes(segment));
                if (diff.length === 0) return;
            }
            prevSegments = segments;
            /* @ts-ignore */
            const segment = this.segments.toReversed().find((segment: Segment) => segment.overMask(Math.floor(x), Math.floor(y)));
            if (!segment) return this.hoveredElements = [];
            const group = this.groups.find(({ segments }) => segments.includes(segment));
            if (group && group !== this.#editingGroup) this.hoveredElements = [group];
            else this.hoveredElements = [segment];
        }
    }

    public setCursor(value: [number, number] | null) {
        this.engine.cursor = value;
    }

    public changeCursorRadius(delta: number) {
        if (delta > 0) this.engine.cursorRadius += 1;
        else if (this.engine.cursorRadius > 3) this.engine.cursorRadius -= 1;
    }

    public tabHover(rawX: number, rawY: number) {
        if (!this.#hoveredElements.length) return;
        const { x, y } = this.transformCoordinates(rawX, rawY);
        /* @ts-ignore */
        const segments = this.segments.filter((segment: Segment) => segment.overMask(Math.floor(x), Math.floor(y))).toReversed();

        const hoveredElement = this.#hoveredElements[0];

        const index = segments.findIndex((segment: Segment) => {
            if (hoveredElement instanceof Segment) return segment === hoveredElement;
            else return hoveredElement.segments.includes(segment);
        });
        const segment = segments[(index + 1) % segments.length];
        const element = this.#groups.find(({ segments }) => segments.includes(segment)) || segment;
        this.hoveredElements = [element];
    }

    public async combineSegments(segments: Array<Segment>, including: boolean) {
        if (!segments.length) throw new Error('Need 2 or more segments.');

        const segment = this.createNewSegment();

        segments.forEach(s => s.mask.forEach((value, index) => segment.mask[index] |= value));
        segment.updateBoundingRectangle();
        this.engine.updateSprite(segment);

        if (including) {
            segment.description = segments.map(({ description }) => description).filter(Boolean).join(', ');
            segment.attachments = [...new Set(segments.flatMap(segment => segment.attachments))];
        }
        segment.saveMask();

        const firstIndex = this.#list.findIndex(element => segments.includes(element as Segment));

        segments.forEach(segment => {
            const index = this.segments.findIndex(s => s === segment);
            if (~index) this.segments.splice(index, 1);
        });

        this.list = this.#list.filter(element => !segments.includes(element as Segment));
        this.segments.sort(({ maskSize: a }, { maskSize: b }) => b - a);
        /* @ts-ignore */
        this.list = this.#list.toSpliced(firstIndex, 0, segment);
        segments.forEach(segment => this.engine.deleteSprite(segment));
        this.saveSketch();
        setTimeout(() => {
            const element = document.getElementById('element_' + segment.id);
            element?.scrollIntoView({ block: 'center' });
        }, 10);
        return segment;
    }

    public editGroupHandler(adding: boolean) {
        if (!this.#editingGroup) return;
        const group = this.#editingGroup;
        const segments = this.#hoveredElements.filter(element => element instanceof Segment) as Array<Segment>;
        segments.forEach(segment => {
            if (!(segment instanceof Segment)) return;
            const included = group.segments.includes(segment);
            if (included) {
                if (adding) return;
                group.removeSegment(segment);
                this.list = [...this.#list, segment];
            } else {
                if (!adding) return;
                const alreadyInGroup = this.groups.flatMap(({ segments }) => segments).includes(segment);
                if (alreadyInGroup) return;
                group.addSegment(segment, 0);
                this.list = this.#list.filter(s => s !== segment);
            }
        })
    }

    public scale(event: React.WheelEvent<HTMLCanvasElement>) {
        // Эта функция была написана по принципу, если долго и не осмысленно писать символы, то когда-нибудь из них получится правильно работающая программа
        const scale =
            event.deltaY > 0
                ? Math.max(this.engine.scale / 1.1, 0.3)
                : Math.min(this.engine.scale * 1.1, 3);

        const { offsetX, offsetY } = event.nativeEvent;
        const { width, height } = event.currentTarget;

        const multiplier = event.deltaY > 0
            ? this.engine.scale / scale
            : scale / this.engine.scale;

        const scaleMatrix = this.engine.calcScaleMatrix2();

        const cursorX = offsetX / width * 2 - 1;
        const pictureX = this.engine.translate[0] - scaleMatrix[0];

        const cursorY = 1 - offsetY / height * 2;
        const pictureY = this.engine.translate[1] + scaleMatrix[1];

        const deltaX = (cursorX - pictureX - scaleMatrix[0]);
        const deltaY = (pictureY - scaleMatrix[1] - cursorY);

        const valueX = deltaX * (multiplier - 1);
        const valueY = deltaY * (multiplier - 1);

        event.deltaY > 0 ? this.engine.translate[0] += valueX / multiplier : this.engine.translate[0] -= valueX;
        event.deltaY > 0 ? this.engine.translate[1] -= valueY / multiplier : this.engine.translate[1] += valueY;
        this.engine.scale = scale;
    }

    public scaleFromButton(value: number) {
        const scale =
            value > 0
                ? Math.max(this.engine.scale / 1.1, 0.3)
                : Math.min(this.engine.scale * 1.1, 3);

        this.engine.scale = scale;
    }

    public translate(translate: [number, number]) {
        this.engine.translate[0] += translate[0] * 2;
        this.engine.translate[1] += translate[1] * 2;
    }

    public fit() {
        this.engine.translate = [0, 0];
        this.engine.scale = 1;
    }
}

export function useAttachment<T extends keyof Pick<Attachment, 'note'>>(attachment: Attachment, key: T) {
    const [value, setValue] = useState(attachment[key]);

    useEffect(() => {
        setValue(attachment[key]);
        attachment.addListener(key, setValue);
        return () => void attachment.removeListener(key, setValue);
    }, [attachment, key]);

    return value;
}

export function useSegment<T extends keyof Pick<Segment, 'description' | 'name' | 'attachments' | 'state'>>(segment: Segment, key: T) {
    const [value, setValue] = useState(segment[key]);

    useEffect(() => {
        setValue(segment[key]);
        segment.addListener(key, setValue);
        return () => void segment.removeListener(key, setValue);
    }, [segment, key]);

    return value;
}

export function useGroup<T extends keyof Pick<Group, 'segments' | 'description' | 'attachments'>>(group: Group, key: T) {
    const [value, setValue] = useState(group[key]);

    useEffect(() => {
        group.addListener(key, setValue);
        return () => void group.removeListener(key, setValue);
    }, [group, key]);

    return value;
}

export function useEditor<T extends keyof Pick<SegmentEditor, 'editing' | 'undo' | 'redo' | 'segment' | 'onnxState'>>(key: T) {
    const editor = useManager('segmentEditor');
    const [value, setValue] = useState(editor[key]);

    useEffect(() => {
        editor.addListener(key, setValue);
        return () => void editor.removeListener(key, setValue);
    }, [editor, key]);

    return value;
}

export function useInitiator<T extends keyof Pick<Initiator, 'prototyping' | 'fileName' | 'initStep'>>(key: T) {
    const { initiator } = useManager('self');
    const [value, setValue] = useState(initiator[key]);

    useEffect(() => {
        initiator.addListener(key, setValue);
        return () => void initiator.removeListener(key, setValue);
    }, [initiator, key]);

    return value;
}

export function useManager<T extends keyof Pick<Manager, 'segments' | 'groups' | 'self' | 'list' | 'editingGroup' | 'selectedElements' | 'matchDefinition' | 'segmentEditor' | 'hoveredElements' | 'name' | 'deltaFrame' | 'selectedBackground'>>(key: T): Manager[T] {
    const manager = useStore($manager);
    if (!manager) throw new Error('Manager is undefined.');
    const [value, setValue] = useState(manager[key]);

    useEffect(() => {
        if (!manager) return;
        setValue(manager[key]);
        manager.addListener(key, setValue);
        return () => void manager.removeListener(key, setValue);
    }, [manager, key]);

    return value;
}

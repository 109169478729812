import { useStore } from 'effector-react';
import SelectForm from '../SelectForm';
import ColorForm from './ColorForm';
import { $renderSettings, updateRenderSettings } from 'entities/render';
import './GeneralizedMaterialQualities.scss';


type GeneralizedMaterialQualitiesProps = {
    checked: boolean;
};

export default function GeneralizedMaterialQualities({ checked }: GeneralizedMaterialQualitiesProps) {
    const renderSettings = useStore($renderSettings);

    return (
        <div className="generalized-material-qualities">
            <div className="generalized-material-qualities__column">
                <div className="generalized-material-qualities__column-title">TONES</div>
                <div className="generalized-material-qualities__tones">
                    <ColorForm checked={checked} state={renderSettings.primaryTone} setState={value => updateRenderSettings({ key: 'primaryTone', value })} label="Select Primary tone" />
                    <ColorForm checked={checked} state={renderSettings.secondaryTone} setState={value => updateRenderSettings({ key: 'secondaryTone', value })} label="Select Secondary tone" />
                </div>
                <div className="generalized-material-qualities__left-selects">
                    <SelectForm checked={checked} state={renderSettings.colorMode} setState={value => updateRenderSettings({ key: 'colorMode', value })} placeholder="How diverse is the design color palette?" options={['Rather monochrome than colorful', 'Rather colorful than monochrome']} />
                    <SelectForm checked={checked} state={renderSettings.paletteTemperature} setState={value => updateRenderSettings({ key: 'paletteTemperature', value })} placeholder="Choose design palette temperature?" options={['Rather cold than warm', 'Rather warm than cold']} />
                </div>
            </div>
            <div className="generalized-material-qualities__column">
                <div className="generalized-material-qualities__column-title">MATERIAL QUALITIES</div>
                <div className="generalized-material-qualities__right-selects">
                    <SelectForm checked={checked} state={renderSettings.materialDiversity} setState={value => updateRenderSettings({ key: 'materialDiversity', value })} placeholder="How diverse are the materials?" options={['Rather uniform than diverse', 'Rather diverse than uniform']} />
                    <SelectForm checked={checked} state={renderSettings.materialTypePreference} setState={value => updateRenderSettings({ key: 'materialTypePreference', value })} placeholder="Which materials prevail?" options={['Rather natural than artificial', 'Rather artificial than natural']} />
                    <SelectForm checked={checked} state={renderSettings.textureDominance} setState={value => updateRenderSettings({ key: 'textureDominance', value })} placeholder="Which material textures dominate in the design?" options={['Rather plain than active', 'Rather active than plain']} />
                    <SelectForm checked={checked} state={renderSettings.surfaceFinish} setState={value => updateRenderSettings({ key: 'surfaceFinish', value })} placeholder="Which surfaces dominate in the design?" options={['Rather shiny than matte', 'Rather matte than shiny']} />
                    <SelectForm checked={checked} state={renderSettings.detailIntegration} setState={value => updateRenderSettings({ key: 'detailIntegration', value })} placeholder="How strong are details integrated in the design?" options={['Rather integrated than utilitarian', 'Rather utilitarian than integrated']} />
                </div>
            </div>
        </div>
    );
}

import classNames from 'classnames';
import './Button.scss';


type ButtonProps = {
    className?: string;
    disabled?: boolean;
    children: React.ReactNode;
    onClick?: () => void;
    id?: string;
    size: 'large' | 'medium' | 'small';
    color: 'dark' | 'white';
};

export default function Button({ disabled = false, children = '', className = '', id, color = 'white', size, onClick }: ButtonProps) {
    return (
        <button
            className={classNames({
                button: true,
                ['button_' + size]: true,
                ['button_' + color]: true,
                [className]: Boolean(className),
            })}
            id={id}
            disabled={disabled}
            onClick={onClick}
        >
            {children}
        </button>
    );
}

import { useCallback } from 'react';
import Button from 'Components/Button';
import LeftSidePanel from '../LeftSidePanel';
import { setLeftSidePanel, LeftSidePanel as LeftSidePanelEnum } from 'entities/leftSidePanel';
import { RoutePath, closeSketch, goTo } from 'entities/router';
import './SaveBeforeClosing.scss';


export default function SaveBeforeClosing() {
    const no = useCallback(() => setLeftSidePanel(LeftSidePanelEnum.NONE), []);
    const yes = useCallback(() => {
        goTo(RoutePath.HOME);
        setLeftSidePanel(LeftSidePanelEnum.NONE);
        closeSketch();
    }, []);

    return (
        <LeftSidePanel>
            <div className="save-before-closing">
                <div className="save-before-closing__question">Save changes before closing?</div>
                <div className="save-before-closing__buttons">
                    <Button size="medium" color="white" onClick={yes}>YES</Button>
                    <Button size="medium" color="white" onClick={no}>NO</Button>
                </div>
            </div>
        </LeftSidePanel>
    );
}

import { ReactElement, cloneElement, useCallback, useMemo } from 'react';
import './Hint.scss';

type HintProps = {
    children: ReactElement;
};

export default function Hint({ children }: HintProps) {
    const onMouseEnter = useCallback(() => console.log('enter'), []);
    const onMouseLeave = useCallback(() => console.log('leave'), []);

    const element = useMemo(() => cloneElement(children, { onMouseEnter, onMouseLeave }), [children, onMouseEnter, onMouseLeave]);

    return element;
}
import { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import Button from 'Components/Button';
import * as Listeners from '../Listeners';
import { Group, Manager, ManagerInitStep, Segment, useEditor, useInitiator, useManager } from 'entities/sketch/Manager';
import { ReactComponent as EditSVG } from './icons/edit.svg';
import { ReactComponent as CheckSVG } from './icons/check.svg';
import { ReactComponent as FrameToAddSVG } from './icons/frame_to_add.svg';
import { ReactComponent as PointToAddSVG } from './icons/point_to_add.svg';
import { ReactComponent as EraserSVG } from './icons/eraser.svg';
import { ReactComponent as BrushSVG } from './icons/brush.svg';
import { ReactComponent as UndoSVG } from './icons/arrow_left.svg';
import { ReactComponent as RedoSVG } from './icons/arrow_right.svg';
import { ReactComponent as CursorSelectSVG } from './icons/cursor_select.svg';
import { ReactComponent as CursorHandSVG } from './icons/cursor_hand.svg';
import { ReactComponent as JoinSVG } from './icons/join.svg';
import { ReactComponent as EntourageSVG } from './icons/entourage.svg';
import { setHint } from 'entities/hint';
import { useHotKey } from 'hooks';
import { useHistory } from 'entities/sketch/History';
import './LeftBar.scss';


type LeftBarProps = {
    listener: Listeners.Listener;
    setListener: React.Dispatch<React.SetStateAction<Listeners.Listener>>;
};

class EndingButtons {
    constructor(protected readonly manager: Manager) { }
    handleDone() { };
    handleCancel() { };
}

class EditEndingButton extends EndingButtons {
    handleDone(): void {
        this.manager.segmentEditor.endEditing();
    }

    handleCancel() {
        this.manager.segmentEditor.cancelEditSegment();
    }
}

class CreateEndingButton extends EndingButtons {
    handleDone(): void {
        this.manager.segmentEditor.endCreating();
    }

    handleCancel() {
        this.manager.segmentEditor.cancelCreateSegment();
    }
}

export default function LeftBar({ listener, setListener }: LeftBarProps) {
    const editingGroup = useManager('editingGroup');
    const editing = useEditor('editing');
    const manager = useManager('self');
    const initStep = useInitiator('initStep');
    const [endingButtons, setEndingButtons] = useState<EndingButtons>(() => new EndingButtons(manager));

    const selectDisabled = useMemo(() => editing || initStep !== ManagerInitStep.READY, [editing, initStep]);

    useEffect(() => {
        if (initStep !== ManagerInitStep.READY) return setListener(new Listeners.Zoom(manager));
        if (editing) return setListener(new Listeners.BoxListener(manager));
        if (editingGroup) return setListener(new Listeners.EditGroup(manager));
        setListener(new Listeners.Select(manager));
    }, [editing, setListener, editingGroup, manager, initStep]);

    const handleClickHightlight = useCallback(() => {
        if (selectDisabled) return;

        if (editingGroup) return setListener(new Listeners.EditGroup(manager));
        else return setListener(new Listeners.Select(manager));
    }, [selectDisabled, manager, setListener, editingGroup]);

    const clickEsc = useCallback(() => {
        if (!editing) return;
        endingButtons.handleCancel();
    }, [editing, endingButtons]);

    useHotKey('Escape', clickEsc);

    return (
        <div className="left-bar">
            {editing ? <SegmentEditorArrows /> : <GlobalArrows />}
            <div className="left-bar__br" />
            <div className="left-bar__cursor-buttons" id="left-bar__cursor-buttons">
                <CursorSelectSVG
                    id="select"
                    className={classNames({ 'left-bar__cursor-button': true, 'left-bar__cursor-button_active': listener instanceof Listeners.Select, 'left-bar__cursor-button_disabled': selectDisabled })}
                    onClick={handleClickHightlight}
                    onMouseEnter={() => setHint({ id: 'select' })}
                    onMouseLeave={() => setHint(null)}
                />
                <CursorHandSVG
                    id="move"
                    className={classNames({ 'left-bar__cursor-button': true, 'left-bar__cursor-button_active': listener instanceof Listeners.Zoom })}
                    onClick={() => setListener(new Listeners.Zoom(manager))}
                    onMouseEnter={() => setHint({ id: 'move' })}
                    onMouseLeave={() => setHint(null)}
                />
            </div>
            <div className="left-bar__br" />
            {editing
                ?
                <EditButtons listener={listener} setListener={setListener} endingButtons={endingButtons} />
                :
                <ToolsButtons setEndingButtons={setEndingButtons} />
            }
        </div >
    );
}

type EditButtonsProps = {
    listener: Listeners.Listener;
    setListener: React.Dispatch<React.SetStateAction<Listeners.Listener>>;
    endingButtons: EndingButtons;
};

function EditButtons({ listener, setListener, endingButtons }: EditButtonsProps) {
    const undoArray = useEditor('undo');
    const manager = useManager('self');
    /*     const prevSketchState = useStore($prevSketchState) */

    return (
        <>
            <div
                id="left-bar__icon1"
                className={classNames({ 'left-bar__button': true, 'left-bar__button_active': listener instanceof Listeners.BoxListener })}
                onClick={() => setListener(new Listeners.BoxListener(manager))}
                onMouseEnter={() => setHint({ id: 'left-bar__icon1' })}
                onMouseLeave={() => setHint(null)}
            >
                <FrameToAddSVG />
                <div className="left-bar__button-text">FRAME<br />TO ADD</div>
            </div>
            <div
                id="left-bar__icon2"
                className={classNames({ 'left-bar__button': true, 'left-bar__button_active': listener instanceof Listeners.PositivePoint })}
                onClick={() => setListener(new Listeners.PositivePoint(manager))}
                onMouseEnter={() => setHint({ id: 'left-bar__icon2' })}
                onMouseLeave={() => setHint(null)}>
                <PointToAddSVG />
                <div className="left-bar__button-text">POINT<br />TO ADD</div>
            </div>
            <div
                id="left-bar__icon3"
                className={classNames({ 'left-bar__button': true, 'left-bar__button-special': true, 'left-bar__button-special_active': listener instanceof Listeners.NegativePoint })}
                onClick={() => setListener(new Listeners.NegativePoint(manager))}
                onMouseEnter={() => setHint({ id: 'left-bar__icon3' })}
                onMouseLeave={() => setHint(null)}
            >
                <div className="left-bar__button-point-to-exclude" />
                <div className="left-bar__button-text">POINT<br />TO EXCLUDE</div>
            </div>
            <div
                id="left-bar__icon5"
                className={classNames({ 'left-bar__button': true, 'left-bar__button_active': listener instanceof Listeners.BrushListener })}
                onClick={() => setListener(new Listeners.BrushListener(manager))}
                onMouseEnter={() => setHint({ id: 'left-bar__icon5' })}
                onMouseLeave={() => setHint(null)}
            >
                <BrushSVG />
                <div className="left-bar__button-text">BRUSH</div>
            </div>
            <div
                className={classNames({ 'left-bar__button': true, 'left-bar__button_active': listener instanceof Listeners.EraserListener })}
                onClick={() => setListener(new Listeners.EraserListener(manager))}
                id="left-bar__icon6"
                onMouseEnter={() => setHint({ id: 'left-bar__icon6' })}
                onMouseLeave={() => setHint(null)}
            >
                <EraserSVG />
                <div className="left-bar__button-text">ERASER</div>
            </div>
            <div className="left-bar__editing-buttons">
                <Button className="left-bar__done-button" disabled={Number(undoArray?.length) < 1} size="small" color="dark" onClick={() => endingButtons.handleDone()}>
                    <CheckSVG />
                    <div>DONE</div>
                </Button>
                <Button size="small" color="white" onClick={() => endingButtons.handleCancel()}>CANCEL</Button>
            </div>
        </>
    );
}

type ToolsButtonsProps = {
    setEndingButtons: React.Dispatch<React.SetStateAction<EndingButtons>>;
};

function ToolsButtons({ setEndingButtons }: ToolsButtonsProps) {
    const initStep = useInitiator('initStep');
    const manager = useManager('self');
    const editingGroup = useManager('editingGroup');
    const selectedElements = useManager('selectedElements');

    const indicateDisabled = useMemo(() => {
        if (initStep !== ManagerInitStep.READY) return true;
        if (editingGroup) return true;
        if (selectedElements.length > 1) return true;
        if (selectedElements[0] instanceof Group) return true;
        return false;
    }, [editingGroup, selectedElements, initStep]);

    const joinDisabled = useMemo(() => {
        if (initStep !== ManagerInitStep.READY) return true;
        if (editingGroup) return true;
        if (selectedElements.length < 2) return true;
        if (selectedElements.some(element => element instanceof Group || element.groupId)) return true;
    }, [editingGroup, selectedElements, initStep]);

    const onClick = useCallback(async () => {
        if (indicateDisabled) return;
        const segment = selectedElements[0];
        if (segment instanceof Group) throw new Error('Selected element instanceof Group.');

        if (!segment) {
            const segment = manager.createNewSegment();
            manager.segmentEditor.editSegment(segment);
            setEndingButtons(new CreateEndingButton(manager));
        } else {
            manager.segmentEditor.editSegment(segment);
            setEndingButtons(new EditEndingButton(manager));
        }
    }, [manager, selectedElements, indicateDisabled, setEndingButtons]);

    const join = useCallback(async () => {
        if (joinDisabled) return;

        const needAsk = (selectedElements as Array<Segment>).some(segment => segment.description || segment.attachments.length);
        const segment = await manager.combineSegments(selectedElements as Array<Segment>, needAsk);
        manager.selectOneElement(segment);
    }, [selectedElements, manager, joinDisabled]);

    return (
        <>
            <div
                id="left-bar__button_chair"
                className={classNames({ 'left-bar__button': true, 'left-bar__button_disabled': indicateDisabled })}
                onClick={onClick}
                onMouseEnter={() => setHint({ id: 'left-bar__button_chair' })}
                onMouseLeave={() => setHint(null)}
            >
                <EditSVG />
                <div>CREATE/EDIT OBJECT MASK</div>
            </div >
            <div
                className={classNames({ 'left-bar__button': true, 'left-bar__button_disabled': joinDisabled })}
                onClick={join}
            >
                <JoinSVG />
                <div>JOIN MASKS</div>
            </div>
            <div className="left-bar__br" />
            <div
                className={classNames({ 'left-bar__button': true, 'left-bar__button_disabled': true })}
            >
                <EntourageSVG />
                <div>ENTOURAGE<br />CUT-OUTS</div>
            </div>
        </>
    );
}

function SegmentEditorArrows() {
    const segmentEditor = useManager('segmentEditor');
    const undoArray = useEditor('undo');
    const redoArray = useEditor('redo');

    const undoDisabled = useMemo(() => Number(undoArray.length) < 1, [undoArray]);
    const redoDisabled = useMemo(() => !redoArray.length, [redoArray]);

    const undo = useCallback(() => {
        if (undoDisabled) return;
        segmentEditor.undoAction();
    }, [segmentEditor, undoDisabled]);

    const redo = useCallback(() => {
        if (redoDisabled) return;
        segmentEditor.redoAction();
    }, [segmentEditor, redoDisabled]);

    useHotKey('z', undo, useMemo(() => ({ modifier: 'ctrlKey' }), []));
    useHotKey('y', redo, useMemo(() => ({ modifier: 'ctrlKey' }), []));

    return (
        <div className="left-bar__arrows" id="undo_redo" onMouseEnter={() => setHint({ id: 'undo_redo' })}
            onMouseLeave={() => setHint(null)}>
            <UndoSVG className={classNames({ 'left-bar__arrow': true, 'left-bar__arrow_disabled': undoDisabled })} onClick={undo} />
            <RedoSVG className={classNames({ 'left-bar__arrow': true, 'left-bar__arrow_disabled': redoDisabled })} onClick={redo} />
        </div>
    );
}

function GlobalArrows() {
    const manager = useManager('self');
    const history = useHistory(manager, 'self');
    const undoArray = useHistory(manager, 'undo');
    const redoArray = useHistory(manager, 'redo');

    const undoDisabled = useMemo(() => Number(undoArray.length) < 1, [undoArray]);
    const redoDisabled = useMemo(() => !redoArray.length, [redoArray]);

    const undo = useCallback(() => {
        if (undoDisabled) return;
        history.undoAction();
    }, [history, undoDisabled]);

    const redo = useCallback(() => {
        if (redoDisabled) return;
        history.redoAction();
    }, [history, redoDisabled]);

    useHotKey('z', undo, useMemo(() => ({ modifier: 'ctrlKey' }), []));
    useHotKey('y', redo, useMemo(() => ({ modifier: 'ctrlKey' }), []));

    return (
        <div className="left-bar__arrows" id="undo_redo" onMouseEnter={() => setHint({ id: 'undo_redo' })}
            onMouseLeave={() => setHint(null)}>
            <UndoSVG className={classNames({ 'left-bar__arrow': true, 'left-bar__arrow_disabled': undoDisabled })} onClick={undo} />
            <RedoSVG className={classNames({ 'left-bar__arrow': true, 'left-bar__arrow_disabled': redoDisabled })} onClick={redo} />
        </div>
    );
}
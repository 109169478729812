import { useStore } from "effector-react";
import { $notification, setNotification, toggleNotificationShow } from "entities/notification";
/* import { getNotificationText } from "./helper"; */
import { CheckboxBlack } from "Components/Checkbox/Checkbox";
import './Notification.scss'


export default function Notification() {
    const notification = useStore($notification)

    return (
        <>
            {notification &&
                <div className="notification">
                    <div className="notification_header">
                        <div className="notification_header-type">
                            <div className={`notification-type_icon ${notification.type.toLowerCase()}_icon`} />
                            <p>{notification.title}</p>
                        </div>
                        <div className="notification_close-icon" onClick={() => setNotification(null)} />
                    </div>
                    <div className="notification_text">
                        {/* {getNotificationText(notification.notification, filename)} */}
                        {notification.show_again !== undefined &&
                            <div className="notification_checkbox-wrapper">
                                <CheckboxBlack checked={!notification.show_again} onChange={() => toggleNotificationShow()} className="notification_checkbox" />
                                <p>Sure, don't show this message again</p>
                            </div>
                        }
                    </div>
                </div>
            }
        </>
    );
}

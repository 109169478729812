import { useCallback, useEffect, useMemo, useState } from 'react';
import { setElementForAttach, uploadFx } from 'entities/library';
import { Group, Segment, useManager } from 'entities/sketch/Manager';
import { TextareaAutosize } from '@mui/material';
import classNames from 'classnames';
import { Tab } from '../Attachments';
import { Notifications, setNotification } from 'entities/notification';
import './Upload.scss';


type Props = {
    element: Segment | Group;
    setTab: (tab: Tab) => void;
};

const extensions = [
    '.adsklib',
    '.c4d',
    '.dae',
    '.dwg',
    '.dxf',
    '.ifc',
    '.jpeg',
    '.jpg',
    '.lcf',
    '.ldt',
    '.mat',
    '.pdf',
    '.png',
    '.rfa',
    '.rvt',
    '.skp',
    '.txt',
    '.uld',
    '.webp',
    '.3ds',
];

export default function Upload({ element, setTab }: Props) {
    const manager = useManager('self');
    const [file, setFile] = useState<File | null>(null);
    const [note, setNote] = useState('');
    const [isFocused, setIsFocused] = useState<boolean>(false)

    const checkEndSetFile = useCallback((file: File | undefined) => {
        setNotification(null)
        if (!file) throw new Error('File not exist.');
        const ext = '.' + file.name.split('.').splice(-1)[0];
        const maxSize = 36 * 1024 * 1024;
        if (!extensions.includes(ext)) return setNotification(Notifications.UPLOAD_EXT_ERROR)
        if (file.size > maxSize) return setNotification(Notifications.UPLOAD_SIZE_ERROR)

        setFile(file);
    }, []);

    const handleDrop = useCallback((e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        checkEndSetFile(file);
    }, [checkEndSetFile]);

    const handleDrag = useCallback((e: React.DragEvent<HTMLDivElement>) => e.preventDefault(), []);

    const handleUpload = useCallback(() => {
        const input = document.getElementById('input');
        if (!input) throw new Error('Input not found.');
        input.click();
    }, []);

    const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        const files = e.target.files;
        if (!files) return console.error('Files not exist.');
        const file = files[0];
        if (!file) return;
        checkEndSetFile(file);
    }, [checkEndSetFile]);

    useEffect(() => {
        const button = document.getElementById('apply-attachments');
        if (!(button instanceof HTMLButtonElement)) return console.error('Button not found.');
        if (!file) return;
        button.onclick = () => {
            uploadFx({ file, note }).then(data => element.attach(data));
            manager.selectOneElement(element);
            setElementForAttach(null);
            setTab(Tab.UPLOAD);
        };
    }, [file, note, element, manager, setTab]);

    const fileIsImage = useMemo(() => {
        if (!file) return false;
        return ['image/jpeg', 'image/jpg', 'image/png'].includes(file.type);
    }, [file]);

    return (
        <div className='upload-attach_wrapper'>
            <p className='upload-attach_descr'>Naturally add any file, as if you were sending it to explain the<br /> object to someone. This can be any file that aids the explanation <br />process from PDFs to Revit families.</p>
            <div className="upload-attach" onDrop={handleDrop} onDragOver={handleDrag}>
                <input id="input" onChange={handleChange} type='file' hidden accept={extensions.join(', ')} />
                {file && fileIsImage ? <LoadedImage file={file} /> : <DragAndDropSpace handleUpload={handleUpload} file={file} />}
            </div>
            {fileIsImage && <ReplaceRow handleUpload={handleUpload} />}

            <div className="upload-attach__about">CLARIFICATION NOTES</div>
            <div className={classNames({ 'upload-attach__note-textarea': true, focus: isFocused })}>
                <TextareaAutosize value={note}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                    onChange={(e) => setNote(e.target.value)}
                    className="upload-attach__note" placeholder="Note" />
            </div>
            <p className='upload-attach__descr'>[ex. page for a pdf, exact object on the reference image, etc.]</p>

            {/* <Input className="upload-attach__note" placeholder="Note" value={note} onChange={e => setNote(e.target.value)} /> */}
        </div>
    );
}

type DragAndDropSpaceProps = {
    handleUpload: () => void;
    file: File | null;
};

function DragAndDropSpace({ handleUpload, file }: DragAndDropSpaceProps) {
    return (
        <>
            {/* <div className="upload-attach__plus">+</div> */}
            <div className='upload-attach__wr'>
                <div className="upload-attach__dd">DRAG <span className='percent-icon'>&</span> DROP OR BROWSE</div>
                <button className="upload-attach__browse-bttn" onClick={handleUpload}>BROWSE</button>
                <div className="upload-attach__ext">36Mb limit<br />One file only</div>
            </div>

            {/* //<span className="brown underline pointer" onClick={handleUpload}>BROWSE</span> */}
            {/* <div className="upload-attach__ext brown">{file ? '.' + file.name.split('.').splice(-1)[0] : 'FILE EXTENSIONS'}</div> */}
        </>
    );
}

type LoadedImageProps = {
    file: File;
};

function LoadedImage({ file }: LoadedImageProps) {
    return (<img className="upload-attach__image" src={URL.createObjectURL(file)} alt="file" />);
}

type ReplaceRowProps = {
    handleUpload: () => void;
};

function ReplaceRow({ handleUpload }: ReplaceRowProps) {
    return (
        <div className="upload-attach__replace">
            <div>UPLOADED FILE NAME</div>
            <div className="brown underline pointer" onClick={handleUpload}>REPLACE FILE</div>
        </div>
    );
}

export enum ModalType {
    NONE = 'NONE',
    SHARE = 'SHARE',
}

export type ShareRenderModal = {
    type: ModalType.SHARE;
    props: {
        id: string;
    };
};

export type Modal = {
    type: ModalType.NONE;
    props?: undefined;
} | ShareRenderModal;

import { useEffect, useState, useCallback } from 'react';
import { useStore } from 'effector-react';
import Header from 'Components/Header';
import SketchList from './SketchList';
import RenderElement from './RenderElement';
import useClickOutside from 'services/useClickOutside';
import { $renders, $sketches, loadRendersFx, loadSketchesFx } from 'entities/sketches';
import { Render, Sketch } from 'services/sketch';
import config from 'config';
import { useManager } from 'entities/sketch/Manager';
import { RightSide, RoutePath, goTo, setRightSide } from 'entities/router';
import { $userId } from 'entities/user';
import { setHint } from 'entities/hint';
import './Home.scss';


export default function HomeWrapper() {
    const userId = useStore($userId);
    const sketches = useStore($sketches);
    const renders = useStore($renders);

    useEffect(() => void loadSketchesFx(userId), [userId]);
    useEffect(() => void loadRendersFx(userId), [userId]);

    if (!sketches.length) return null;

    return (<Home sketches={sketches} renders={renders} />);
}

function Home({ sketches, renders }: { sketches: Array<Sketch>, renders: Array<Render> }) {
    const manager = useManager('self');
    const [selectedRenders, setSelectedRenders] = useState<string[]>([]);
    const [selectedSketch, setSelectedSketch] = useState<Sketch>(sketches[0]);

    const handleCheckboxClick = (id: string) => setSelectedRenders(selectedRenders.includes(id) ? selectedRenders.filter(renderId => renderId !== id) : [...selectedRenders, id]);

    const openPrototype = useCallback((sketch: Sketch) => {
        setRightSide(RightSide.LIST);
        goTo(RoutePath.SKETCH);
        manager.initiator.initExistedSketch(sketch.id);
    }, [manager]);

    function getFormatedDate(date: string) {
        const dateObject = new Date(date);
        const day = String(dateObject.getDate()).padStart(2, '0');
        const month = String(dateObject.getMonth() + 1).padStart(2, '0');
        const year = dateObject.getFullYear();

        return `${day}/${month}/${year}`;
    }

    const createNewPrototype = useCallback(() => {
        setRightSide(RightSide.DOWNLOAD_FORM);
        goTo(RoutePath.SKETCH);
        manager.initiator.initBlank();
    }, [manager]);

    return (
        <div className="home_wrapper">
            <Header onLogoClick={() => window.location.href = 'https://quarters-dev.site/'} />
            <div className="home_elements_wrapper">
                <div className="home_prototypes_list-wrapper">
                    <div className="home_prototypes_list-header">
                        <p>PROTOTYPES</p>
                        <div className="list-header_create-new">
                            <div className="create-new_add" onClick={createNewPrototype}>
                                <div className="create-new_add-icon" />
                                <p>CREATE NEW PROTOTYPE</p>
                            </div>
                            <div
                                className="create-new_info-icon"
                                id='home_create-info'
                                onMouseEnter={() => setHint({ id: 'home_create-info' })}
                                onMouseLeave={() => setHint(null)}
                            />
                        </div>
                    </div>
                    <div className="home_prototypes_list">
                        {sketches
                            .sort((a, b) => new Date(b.lastModified).getTime() - new Date(a.lastModified).getTime())
                            .map((sketch, index) => (<SketchList sketch={sketch} key={sketch.id} isSelected={selectedSketch === sketch} index={index} setSelectedSketch={setSelectedSketch} openPrototype={openPrototype} />))
                        }
                    </div>
                </div>
                <div className="home_prototypes_preview-wrapper">
                    <div className="home_prototypes_preview-header">
                        <p>{selectedSketch.name}</p>
                    </div>
                    <div className="home_prototypes_preview">
                        <div className="prototype_preview">
                            <div className="prototype_preview-image" onClick={() => openPrototype(selectedSketch)}>
                                <img src={config.backendUrl + '/api/sketch/' + selectedSketch.id + '.png'} className='prototype_preview-image-segments' alt={selectedSketch.name} />
                            </div>
                            <div className="prototype_preview-info">
                                <div className="prototype_preview-info-date">
                                    <div className="preview-info-date-item">
                                        <p className="preview-info-date-title">CREATED</p>
                                        <p>{getFormatedDate(selectedSketch.date)}</p>
                                    </div>
                                    <div className="preview-info-date-item">
                                        <p className="preview-info-date-title">LAST MODIFIED</p>
                                        <p>{getFormatedDate(selectedSketch.lastModified)}</p>
                                    </div>
                                </div>
                                <div className="prototype_preview-open" onClick={() => openPrototype(selectedSketch)}>
                                    <div className="prototype_open-icon" />
                                    <p>OPEN PROTOTYPE</p>
                                </div>
                            </div>
                        </div>
                        <div className="renders_wrapper">
                            <div className="renders_header">
                                <p>RENDERS</p>
                                <RendersDropDown isSelectedRenders={Boolean(selectedRenders.length)} />
                            </div>
                            <div className="renders-box">
                                {renders.length === 0
                                    ?
                                    <p className="no_renders">THERE ARE NO RENDERS YET</p>
                                    :
                                    renders.map((render, index) => (<RenderElement key={index} isSelected={selectedRenders.includes(index.toString())} handleCheckboxClick={handleCheckboxClick} render={render} />))
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

function RendersDropDown({ isSelectedRenders }: { isSelectedRenders: boolean }) {
    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
    const dropdownRef = useClickOutside(() => setIsMenuOpen(false));

    return (
        <div className="download_dropdown-wrapper" ref={dropdownRef} onClick={() => setIsMenuOpen((prev) => !prev)}>
            <div className="download_icon" />
            <p>DOWNLOAD</p>
            <div className={`dropdow_arrow ${isMenuOpen ? 'active' : ''}`} />
            {isMenuOpen && <div className="download-menu">
                <p className={isSelectedRenders ? '' : 'disabled_menu-item'}>selected</p>
                <p>all</p>
            </div>}
        </div>
    );
}

import { useCallback, useState } from 'react';
import { useStore } from 'effector-react';
import classNames from 'classnames';
import PersonalSettings from './PersonalSettings';
import BillingSettings from './BillingSettings';
import MailSettings from './MailSettings';
import { $leftSidePanel, LeftSidePanel, setLeftSidePanel } from 'entities/leftSidePanel';
import { logoutFx } from 'entities/user';
import './Account.scss';


export enum AccountSetting {
    PERSONAL = 'PERSONAL',
    BILLING = 'BILLING',
    MAIL = 'MAIL',
}

interface AccountSettingI {
    title: string,
    icon: string,
    setting: AccountSetting,
}

const settings: AccountSettingI[] = [
    {
        title: 'Personal settings',
        icon: 'personal_icon',
        setting: AccountSetting.PERSONAL,
    },
    {
        title: 'Billing',
        icon: 'billing_icon',
        setting: AccountSetting.BILLING,
    },
    {
        title: 'MAILING PREFERENCES',
        icon: 'mail_icon',
        setting: AccountSetting.MAIL,
    },
];

export default function Account() {
    const leftSidePanel = useStore($leftSidePanel);
    const [activeSettings, setActiveSettings] = useState<AccountSetting>(AccountSetting.PERSONAL);

    const logout = useCallback(() => {
        logoutFx();
        setLeftSidePanel(LeftSidePanel.NONE);
    }, []);

    return (
        <>
            <div className={classNames({ "account_wrapper": true, "account_wrapper_open": leftSidePanel === LeftSidePanel.ACCOUNT })}>
                <div className="account">
                    <div className="account__exit-button" onClick={() => setLeftSidePanel(LeftSidePanel.NONE)} />
                    <p className="account-title">Account</p>
                    <div className="account_menu">
                        <div className="account_menu-items">
                            {settings.map(item => (
                                <div className={classNames({ 'account_menu-item': true, 'account_menu-item_active': activeSettings === item.setting })} key={item.setting} onClick={() => setActiveSettings(item.setting)}>
                                    <div className={`account_menu-item-icon ${item.icon} `} />
                                    <p>{item.title}</p>
                                </div>
                            ))}
                        </div>
                        <div className="account_menu-logout" onClick={logout}>
                            <div className="account_menu-item-icon logout_icon" />
                            <p>LOG OUT</p>
                        </div>
                    </div>
                    {activeSettings === AccountSetting.PERSONAL && <PersonalSettings />}
                    {activeSettings === AccountSetting.BILLING && <BillingSettings />}
                    {activeSettings === AccountSetting.MAIL && <MailSettings />}
                </div>
            </div>
            <div className={classNames({ 'fake_cover': true, 'fake_cover_open': leftSidePanel === LeftSidePanel.ACCOUNT })}>
            </div>
        </>
    );
}

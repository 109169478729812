import { useCallback, useState } from 'react';
import { useStore } from 'effector-react';
import * as Listeners from './Listeners';
import Drawing from './Drawing';
import LeftBar from './LeftBar';
import List from './List';
import { useManager } from 'entities/sketch/Manager';
import Header from 'Components/Header';
import { useHotKey } from 'hooks';
import classNames from 'classnames';
import MatchDefinitionHint from './MatchDefinitionHint';
import RenderingSettings from './RenderingSettings';
import DownloadForm from './DownloadForm';
import { $rightSide, RightSide } from 'entities/router';
import './Sketch.scss';


export default function Sketch() {
    const rightSide = useStore($rightSide);
    const matchDefinition = useManager('matchDefinition');
    const manager = useManager('self');
    const [listener, setListener] = useState<Listeners.Listener>(() => new Listeners.Zoom(manager));

    const setMatchDefinition = useCallback(() => {
        if (listener instanceof Listeners.MatchDefinition) manager.matchDefinition = null;
        else setListener(new Listeners.MatchDefinition(manager))
    }, [manager, listener]);

    useHotKey('i', setMatchDefinition);

    return (
        <div
            className={classNames({
                sketch: true,
                'cursor-match-definition': listener instanceof Listeners.MatchDefinition,
                'cursor-match-definition-full': listener instanceof Listeners.MatchDefinition && matchDefinition && matchDefinition.full,
            })}
        >
            <MatchDefinitionHint />
            <Header type="sketch" />
            <div className="sketch__body">
                <LeftBar listener={listener} setListener={setListener} />
                <Drawing listener={listener} setListener={setListener} />
                {rightSide === RightSide.LIST && <List listener={listener} setListener={setListener} />}
                {rightSide === RightSide.RENDERING_SETTINGS && <RenderingSettings />}
                {rightSide === RightSide.DOWNLOAD_FORM && <DownloadForm />}
            </div>
        </div >
    );
}

import { useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import './Select.scss';


type SelectProps<T> = {
    className?: string;
    items: readonly { key: string; value: T }[];
    value: string | undefined;
    setValue: (value: T) => void;
    onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
    onBlur?: () => void;
    //TO DO
    placeholder?: string;
    disabled?: boolean;
    validateError?: boolean;
}

export default function Select<T>({ className, items, value, setValue, onFocus, onBlur, placeholder, disabled = false, validateError = false }: SelectProps<T>) {
    const select = useRef<HTMLDivElement>(null);
    const [, setActive] = useState(false);

    const handleSetValue = useCallback((item: T) => setValue(item), [setValue]);

    useEffect(() => {
        select.current?.blur();
    }, [value]);

    const handleClick = useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        const target = e.currentTarget;
        setActive(prev => {
            if (!prev) target.focus();
            return !prev;
        });
    }, []);

    return (
        <div ref={select} tabIndex={0} className={classNames('select', className, { 'select_disabled': disabled, 'select_error': validateError })} onClick={handleClick} onFocus={onFocus} onBlur={onBlur}>
            <div className={classNames({ 'select__placeholder': true, 'select__placeholder_selected': Boolean(value) })}>{value || placeholder}</div>
            <div className="select__button" />
            <div className="select__items">
                {
                    items.map(({ key, value }, index) => <div className="select__item" key={index} onClick={() => handleSetValue(value)}>{key}</div>)
                }
            </div>
        </div>
    );
}

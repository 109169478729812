import { useEffect, useRef, useState } from "react";
import classNames from 'classnames';
import { ExtendedHintI, PositionI, getFlexDirection, getHintsPosition } from "./helper";
import useElementCoordinates from "services/useElementCoordinates";
import { HintI } from "entities/hint";
import './Hint.scss';


export default function Hint({ hint }: { hint: HintI }) {
    const [showHint] = useState<boolean>(false)
    const [showExtendedHint] = useState<boolean>(false)
    const [extendedHint] = useState<ExtendedHintI | undefined>(undefined)
    const [hintPosition, setHintPosition] = useState<PositionI>({ top: 0, left: 0 });
    const [extendedHintPosition, setExtendedHintPosition] = useState<PositionI>({ top: 0, left: 0 });
    const coordinates = useElementCoordinates(hint.index ? hint.elementId + hint.index : hint.elementId);
    const hintRef = useRef<HTMLDivElement | null>(null);
    const exHintRef = useRef<HTMLDivElement | null>(null);
    const [hintSize, setHintSize] = useState<{ height: number, width: number }>({ height: 0, width: 0 })
    const [extendedHintSize, setExtendedHintSize] = useState<{ height: number, width: number }>({ height: 0, width: 0 })

    useEffect(() => {
        const { topHint, leftHint, topExHint, leftExHint } = getHintsPosition(hint.elementId, hint.arrowPosition, coordinates, hintSize, extendedHintSize);
        setHintPosition({ top: topHint, left: leftHint });
        setExtendedHintPosition({ top: topExHint, left: leftExHint });
    }, [hint.arrowPosition, hint.elementId, coordinates, hintSize, extendedHintSize]);

    useEffect(() => {
        if (hintRef && hintRef.current) {
            setHintSize({ height: hintRef.current.offsetHeight, width: hintRef.current.offsetWidth })
        }
    }, [hintRef, hint])

    useEffect(() => {
        if (exHintRef && exHintRef.current) {
            setExtendedHintSize({ height: exHintRef.current.offsetHeight, width: exHintRef.current.offsetWidth })
        }
    }, [hintRef, hint])

    return (
        <>
            <div className="hint_wrapper"
                ref={hintRef}
                style={{
                    left: hintPosition.left,
                    ...(hint.alignPosition === 'flex-end' ? { bottom: hintPosition.top } : { top: hintPosition.top }),
                    opacity: showHint ? '1' : '0',
                    visibility: showHint ? 'visible' : 'hidden',
                    transition: 'opacity 0.5s, visibility 0.5s',
                    flexDirection: getFlexDirection(hint.arrowPosition),
                    ...(hint.alignPosition && { alignItems: hint.alignPosition })
                }}>
                <div className={classNames({
                    [`hint_before_element_${hint.arrowPosition}`]: true,
                    [`hint_before_${hint.alignPosition}`]: Boolean(hint.alignPosition)
                })} />
                <div className="hint">
                    <p>{hint.text}</p>
                    {hint.extendedHintType && <div className="hint_spinner" />}
                </div>
            </div>
            <div className='big_tooltip_wrapper'
                ref={exHintRef}
                id='big_tooltip'
                style={{
                    left: extendedHintPosition.left,
                    ...(hint.alignPosition === 'flex-end' ? { bottom: extendedHintPosition.top } : { top: extendedHintPosition.top }),
                    opacity: showExtendedHint ? '1' : '0',
                    visibility: showExtendedHint ? 'visible' : 'hidden',
                    transition: 'opacity 0.5s, visibility 0.5s',
                    flexDirection: getFlexDirection(hint.arrowPosition),
                    ...(hint.alignPosition && { alignItems: hint.alignPosition })
                }}
            >
                <div className={classNames({
                    [`hint_before_element_${hint.arrowPosition}`]: true,
                    [`hint_before_${hint.alignPosition}`]: Boolean(hint.alignPosition)
                })} />
                <div className="big_tooltip">
                    <p className="big_tooltip-title">{extendedHint?.title}</p>
                    <div className="big_tooltip-image" />
                    <div className="big_tooltip-text-wrapper">
                        <div className="big_tooltip-text">
                            {extendedHint?.description.map((item, index) => (
                                <p key={index}>{item}</p>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

import { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import { ReactComponent as EmptySVG } from './icons/empty.svg';
import { ReactComponent as ArrowSVG } from './icons/arrow.svg';
import './ColorForm.scss';

const colors = [
    '#FFFFFF',
    '#F8F8F8',
    '#808080',
    '#36454F',
    '#000000',
    '#F5F5DC',
    '#D2B48C',
    '#8B4513',
    '#654321',
    '#644117',
    '#D2691E',
    '#B22222',
    '#C0C0C0',
    '#A8A9AD',
    '#FFD700',
    '#B87333',
    '#8C7853',
    '#E1C16E',
    '#D6CBB5',
    '#C8BD93',
    '#2F4F4F',
    '#708090',
    '#D1E7F3',
    '#B7E3B1',
    '#5B7C99',
    '#355E3B',
];

type ColorFormProps = {
    label: string;
    state: string;
    setState: (value: string) => void;
    checked: boolean;
};

export default function ColorForm({ label, state, setState, checked }: ColorFormProps) {
    const [opened, setOpened] = useState(false);
    const [errored, setErrored] = useState(false);

    const select = useCallback((color: string) => {
        setState(color);
        setOpened(false);
    }, [setState]);

    useEffect(() => void (checked && setErrored(!state)), [checked, state]);

    return (
        <div className="color-form">
            <div className="color-form__label-container">
                <div className="color-form__label">{label}</div>
                {errored && <div className="color-form__error">Select tone</div>}
            </div>
            <div className={classNames({ 'color-form__input': true, 'color-form__input_opened': opened, 'color-form__input_errored': errored })} >
                {state === '' && <EmptySVG />}
                {state !== '' && <div className="color-form__selected-color" style={{ backgroundColor: state }} />}
                <ArrowSVG className={classNames({ pointer: true, 'color-form__arrow_rotated': opened })} onClick={() => setOpened(prev => !prev)} />
                {
                    opened
                    &&
                    <div className="color-form__colorpicker">
                        {
                            colors.map(color => (<div className="color-form__color" style={{ backgroundColor: color }} onClick={() => select(color)} key={color} />))
                        }
                    </div>
                }
            </div >
        </div>
    );
}
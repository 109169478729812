import { useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import Switcher from 'Components/Switcher';
import Button from 'Components/Button';
import Select from 'Components/Select';
import Radio from 'Components/Radio';
import Input from 'Components/Input';
/* import { errorEvent } from 'entities/error'; */
import { LeftSidePanel, setLeftSidePanel } from 'entities/leftSidePanel';
import { useManager } from 'entities/sketch/Manager';
import { ReactComponent as CloseSVG } from './icons/close.svg';
import { ReactComponent as Clip } from './icons/clip.svg'
import { Notifications, setNotification } from 'entities/notification';
import './SubmitForRendering.scss';


const dpi = [{ key: '96', value: '96' }, { key: '64', value: '64' }, { key: '32', value: '32' }];

enum ResolutionType {
    SCREEN = 'SCREEN',
    PRINT = 'PRINT',
}

export default function SubmitForRendering() {
    const manager = useManager('self');
    const [resolutionType, setResolutionType] = useState(ResolutionType.SCREEN);
    const [widthPX, setWidthPX] = useState('800');
    const [heightPX, setHeightPX] = useState('600');
    const [widthMM, setWidthMM] = useState('211.6');
    const [heightMM, setHeightMM] = useState('158.8');
    const [DPI, setDPI] = useState(dpi[0].value);
    const [boost, setBoost] = useState(false);
    const [isSubmSuccess, setIsSubmSuccess] = useState(false);

    const calcDefinedObjects = useMemo(() => manager.segments.filter(segment => segment.description).length, [manager]);

    /*     const getSize = useCallback((): { width: number, height: number } => {
            switch (resolutionType) {
                case ResolutionType.PRINT:
                    return {
                        width: Number(widthMM),
                        height: Number(heightMM),
                    };
                case ResolutionType.SCREEN:
                    return {
                        width: Number(widthPX),
                        height: Number(heightPX),
                    };
                default:
                    throw new Error('Resolution type is nott implemented.');
            }
        }, [widthMM, heightMM, widthPX, heightPX, resolutionType]); */

    const submitForRender = useCallback(() => {
        setIsSubmSuccess(true);
        /*         manager.renderSketch(JSON.stringify(getSize())).catch(errorEvent).then(() => setIsSubmSuccess(true)); */
    }, [/* manager */]);

    if (isSubmSuccess) return (<SubmissionSuccessful />);

    return (
        <div className="submit-for-rendering">
            <div className="submit-for-rendering__window">
                <CloseSVG className="submit-for-rendering__close-button" onClick={() => {
                    setNotification(null);
                    setLeftSidePanel(LeftSidePanel.NONE);
                }} />
                <div className="submit-for-rendering__gap" />
                <div className="submit-for-rendering__title-container">
                    <div className="submit-for-rendering__title">Submit for rendering</div>
                    <div className="submit-for-rendering__email">account_name@mail.com</div>
                </div>
                <div className="submit-for-rendering__main">
                    <div className="submit-for-rendering__resolution">
                        <div>RESOLUTION</div>
                        <div className="submit-for-rendering__settings-resolution-radio submit-for-rendering__settings-resolution-radio-px">
                            <Radio selected={resolutionType === ResolutionType.SCREEN} select={() => setResolutionType(ResolutionType.SCREEN)} />
                            <div>Screen</div>
                        </div>
                        <div className={classNames({ "submit-for-rendering__settings-resolution-inputs": true, 'submit-for-rendering__settings-resolution-inputs_disabled': resolutionType === ResolutionType.PRINT })}>
                            <div className="submit-for-rendering__settings-resolution-input-form">
                                <div>WIDTH</div>
                                <Input className="submit-for-rendering__settings-resolution-input" type="number" value={widthPX} onChange={e => setWidthPX(e.currentTarget.value)} />
                            </div>
                            <div className="submit-for-rendering__settings-resolution-input-form">
                                <div>HEIGHT</div>
                                <Input className="submit-for-rendering__settings-resolution-input" type="number" value={heightPX} onChange={e => setHeightPX(e.currentTarget.value)} />
                            </div>
                            <Clip className="submit-for-rendering__settings-resolution-clip" />
                        </div>
                        <div className="submit-for-rendering__settings-resolution-radio submit-for-rendering__settings-resolution-radio-mm">
                            <Radio selected={resolutionType === ResolutionType.PRINT} select={() => setResolutionType(ResolutionType.PRINT)} />
                            <div>Print</div>
                        </div>
                        <div className={classNames({ "submit-for-rendering__settings-resolution-inputs": true, 'submit-for-rendering__settings-resolution-inputs_disabled': resolutionType === ResolutionType.SCREEN })}>
                            <div className="submit-for-rendering__settings-resolution-input-form">
                                <div>WIDTH</div>
                                <Input className="submit-for-rendering__settings-resolution-input" type="number" value={widthMM} onChange={e => setWidthMM(e.currentTarget.value)} />
                            </div>
                            <div className="submit-for-rendering__settings-resolution-input-form">
                                <div>HEIGHT</div>
                                <Input className="submit-for-rendering__settings-resolution-input" type="number" value={heightMM} onChange={e => setHeightMM(e.currentTarget.value)} />
                            </div>
                            <Clip className="submit-for-rendering__settings-resolution-clip submit-for-rendering__settings-resolution-clip-mm" />
                            <div className="submit-for-rendering__settings-resolution-input-form submit-for-rendering__settings-resolution-input-form-dpi">
                                <div>DPI</div>
                                <Select className="submit-for-rendering__settings-resolution-input" items={dpi} value={DPI} setValue={setDPI} />
                            </div>
                        </div>
                    </div>
                    <div className="submit-for-rendering__br" />
                    <div className="submit-for-rendering__info">
                        <div className="submit-for-rendering__checkout-options">
                            <div className="submit-for-rendering__checkout-option">
                                <div>OBJECTS DEFINED</div>
                                <div className="submit-for-rendering__checkout-option-value">{calcDefinedObjects} OBJECTS</div>
                            </div>
                            <div className="submit-for-rendering__checkout-option">
                                <div>IMAGE SIZE</div>
                                <div className="submit-for-rendering__checkout-option-value">
                                    {widthPX} x {heightPX}
                                    {resolutionType === ResolutionType.PRINT && 'mm'}
                                    {resolutionType === ResolutionType.SCREEN && 'px'}
                                </div>
                            </div>
                            <div className="submit-for-rendering__checkout-option">
                                <div>RENDERING TIME</div>
                                <div className="submit-for-rendering__checkout-option-value">2h 35min 56sec</div>
                            </div>
                            <div className="submit-for-rendering__checkout-option">
                                <div>BOOST RENDERING SPEED</div>
                                <div className="submit-for-rendering__checkout-option-value">
                                    <Switcher value={boost} onClick={() => setBoost(prev => !prev)} />
                                </div>
                            </div>
                        </div>
                        <div className="submit-for-rendering__checkout-amount">
                            <div>TOTAL AMOUNT</div>
                            <div>32 tokens</div>
                        </div>
                        <div className="submit-for-rendering__checkout-balance">
                            <div>available balance</div>
                            <div>10 tokens</div>
                        </div>
                        <div className="submit-for-rendering__checkout-buy-tokens" onClick={() => setNotification(Notifications.NO_PAID_FEATURES_INFO)}>
                            <div>buy tokens</div>
                        </div>
                        <Button className="submit-for-rendering__checkout-button" color="dark" size="medium" onClick={submitForRender}>SUBMIT FOR RENDERING</Button>
                    </div>
                </div>
                <div className="submit-for-rendering__gap" />
                <div className="submit-for-rendering__gap" />
            </div>
        </div>
    );
}

function SubmissionSuccessful() {
    return (
        <div className="submit-for-rendering">
            <div className="submit-for-rendering__window">
                <CloseSVG className="submit-for-rendering__close-button" onClick={() => setLeftSidePanel(LeftSidePanel.NONE)} />
                <div className="submit-for-rendering__successful-wr">
                    <div className="submit-for-rendering__successful-title">Submission successful!</div>
                    <p className="submit-for-rendering__successful-text">
                        <div>A notification will be sent to <span className="highlight-span">account_name@mail.com</span> once the work is completed. All prototype renderings will be stored in the <span className="underline">Prototype Renderings List</span>.</div>
                        <div>To preserve rendering settings of submitted work, continue work in the prototype copy.</div>
                    </p>
                    <div className="submit-for-rendering__buttons">
                        <div className="submit-for-rendering__continue-button" onClick={() => setLeftSidePanel(LeftSidePanel.NONE)}>“Save as” & continue</div>
                        <div className="submit-for-rendering__skip-button" onClick={() => setLeftSidePanel(LeftSidePanel.NONE)}>skip</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

import { useEffect, useRef, useState } from "react";
import useChildElementsHeight from "services/useChildElementsHeight";
import getText from "../helpers/getText";
import { HelpSections, editingToolsItems, interfaceDropdownItems, accountSettingsItems, attachmentTypesItems, SectionItemI } from "../helpers/helpers";
import { useStore } from "effector-react";
import { $helpSection, setHelpSection } from "entities/help";
import { setHint } from "entities/hint";
import './Sections.scss'


export function WhatIsSection({ launchTutorial }: { launchTutorial: () => void }) {
    return (
        <>
            <div className="extended_help-header">
                <div className="extended_help-header-left">
                    <div className="extended_help-book_icon" />
                    <p>Help</p>
                </div>
                <p className="io-buro_link">io-buro.com</p>
            </div>
            <div className="extended_help-text">
                <p className="extended_help-text_title">What is io-buro demo?</p>
                <div className="extended_help_home">
                    <div className="extended_help_home-image" />
                    <p>Imagine creating realistic renderings from concept designs was as ordinary task as pointing out objects in an image and describing them with references and links, just like explaining a design to a friend. That's how io-buro demo works.</p>
                    <p>By using io-buro demo, you help develop the fully-featured io-buro <span className="beta">beta</span>, which we aim to release by late 2024.</p>
                    <div className="extended_help_home_link-wrapper">
                        <div className="home_link-wrapper-icon note_icon" />
                        <p>Important release note</p>
                    </div>

                    <div
                        className="extended_help_home_link-wrapper launch_tutorial-box"
                        onClick={launchTutorial}
                        id="launch_tutorial-info"
                        onMouseLeave={() => setHint(null)}
                    >
                        <div className="home_link-wrapper-icon tutorial_icon" />
                        <p>launch tutorial</p>
                    </div>
                </div>
                <div className="extended_help_home_quick-start">
                    <p className="section_title">Quick start</p>
                    <div className="quick-start_item">
                        <p>1.</p>
                        <p>Click <span className="highlight-span">Create new prototype</span> on the demo home page.</p>
                    </div>
                    <div className="quick-start_item">
                        <p>2.</p>
                        <p><span className="highlight-span">Upload</span> an image to render.</p>
                    </div>
                    <div className="quick-start_item">
                        <p>3.</p>
                        <p>Press <span className="highlight-span">Continue</span>. Wait while AI identifies recognizable objects in the image.</p>
                    </div>
                    <div className="quick-start_item">
                        <p>4.</p>
                        <p>Refine the list of identified objects and describe them in the table.</p>
                    </div>
                    <div className="quick-start_item">
                        <p>5.</p>
                        <p>Click the <span className="highlight-span">Render</span> button to configure the rendering settings, and submit.</p>
                    </div>

                </div>

                {/* <p>The I-O Research Demo allows you to transform concept design sketches into realistic images by providing a detailed description of each object in the sketch. This description is similar to how you would explain the image to clients or colleagues.</p> */}
                {/* {helpList.map((item, index) => (
            <div className="help-text_list-item" key={`hepl_list-${index}`}>
                <p>{index + 1}</p>
                <p>{item}</p>
            </div>
        ))}
        <p>By using this tool, you can help improve it by automating the conversion of file inputs into image explanations. This advancement will allow for instant image creation. Once you submit your case, you will gain access to this advanced demo.</p> */}
            </div>
        </>
    )

}


export function ChoosingImageSection() {
    return (
        <>

            <div className="extended_help-header">
                <div className="extended_help-header_section">
                    <p className="extended_help-header_section-help" onClick={() => setHelpSection({ section: HelpSections.WHAT_IS, itemId: null })}>Help</p>
                    <p>/</p>
                    <p className="extended_help-header_section_title">Choosing image to render</p>

                </div>
            </div>

            <div className="extended_help_upload">
                <p>io-buro aims to provide relief allowing you to concentrate on meaningful design aspects like functionality, feasibility, and sustainability, and rely on io-buro to help communicate your ideas by creating appealing, and comprehensible concept design images in the most natural way.</p>
                <p>We encourage you to create realistic renders from images typically made in the early design stages, such as sketches, photos of models (like paper models), or software screenshots. Interiors, exteriors, everything is welcome.</p>
                <div className="extended_help_upload-example">
                    <div className="extended_help_upload-example_image" />
                    <p>[Example 1]</p>
                </div>
                <div className="extended_help_upload-example">
                    <div className="extended_help_upload-example_image" />
                    <p>[Example 2]</p>
                </div>
                <div className="extended_help_upload-example">
                    <div className="extended_help_upload-example_image" />
                    <p>[Example 3]</p>
                </div>
                <p>Images with true perspective and free of irrelevant details (e.g., axes, tags, mesh grids) are preferable, but any image is welcome for experiments.</p>
            </div>
        </>
    )
}

export function MaskSelectionSection() {
    return (
        <>

            <div className="extended_help-header">
                <div className="extended_help-header_section">
                    <p className="extended_help-header_section-help" onClick={() => setHelpSection({ section: HelpSections.WHAT_IS, itemId: null })}>Help</p>
                    <p>/</p>
                    <p className="extended_help-header_section_title">Mask selection tips and tricks</p>

                </div>
            </div>

            <div className="extended_help_mask_selection">
                <p className="section-descr">
                    When you use viewport choose between 2 cursor modes: <span className="highlight-span">Select masks</span> or <span className="highlight-span">Hand tool</span>.
                </p>
                <div className="section_title">Select masks</div>
                <p>By default <span className="highlight-span">Select masks</span> cursor mode is active. Use the Select Masks cursor mode to choose masks in the viewport.</p>
                <p>This mode automatically disables the <span className="highlight-span">Hand tool</span> mode if it was previously enabled.</p>
                <p>Press and hold <span className="highlight-span">Ctrl</span> (or <span className="highlight-span">Command</span> on Mac) when clicking to add a mask to the current selection.</p>
                <p>Press and hold <span className="highlight-span">Shift</span> when clicking to remove a mask from the current selection.</p>
                <p>Drag the cursor to select multiple masks by creating a selection frame.</p>
                <div className="extended_help_upload-example_image" />
                <p>Drag from left to right to select only the masks fully enclosed by the rectangle.</p>
                <p>Drag from right to left to select masks that are either enclosed or crossed by the rectangle.</p>
            </div>
        </>
    )
}

export function RenderingSection() {
    return (
        <>

            <div className="extended_help-header">
                <div className="extended_help-header_section">
                    <p className="extended_help-header_section-help" onClick={() => setHelpSection({ section: HelpSections.WHAT_IS, itemId: null })}>Help</p>
                    <p>/</p>
                    <p className="extended_help-header_section_title">rendering set up</p>

                </div>
            </div>

            <div className="extended_help_rendering">
                <p>Before sending an image for rendering, configure the rendering settings.</p>
                <div className="help_rendering-item">
                    <div className="section_title">Decide how to render undefined objects</div>
                    <p>When io-buro renders your image, it uses the object definitions. However, it is likely you will have undefined image areas, which is perfectly normal. Decide how to handle these undefined areas.</p>
                    <div className="item-list">
                        <div className="quick-start_item">
                            <p>1.</p>
                            <p>Verify AI's guess on the function depicted in the input image (e.g., kitchen, office, exterior). Manually override if the AI fails to recognize the function correctly.</p>
                        </div>
                        <div className="quick-start_item">
                            <p>2.</p>
                            <p>Verify how the AI recognized the atmosphere based on the objects you defined. Manually adjust with sliders to match your vision.</p>
                        </div>
                    </div>


                </div>
                <div className="help_rendering-item">
                    <div className="section_title">Set up extra scene conditions</div>
                    <p>Select the season, daytime, and weather conditions for your rendering.</p>
                </div>

                <div className="help_rendering-item">
                    <div className="section_title">Set resolution</div>
                    <p>Input resolution in pixels in screen or print resolution input mode.</p>
                </div>

                <div className="help_rendering-item">
                    <div className="section_title">Submit for rendering</div>
                    <div className="item-list">
                        <p>Review your rendering settings and submit.</p>
                        <p>Enable <span className="highlight-span">Speed boost</span> to speed up your rendering using cloud power for immediate processing.</p>
                        <p>Rendering with default resolution and speed settings is <span className="highlight-span">free</span>.</p>
                        <p>Click <span className="highlight-span">Reset default resolution and speed</span> to return to free rendering settings.</p>
                        <div className="help_rendering-item-link">
                            <p>Click <span className="highlight-span">Buy credits</span> to top up.</p>
                            <div className="read_more-link">
                                Read more
                                <div className="go_arrow" />
                            </div>
                        </div>
                        <div className="help_rendering-item-link">
                            <p>Note: You will get 2 image renders.</p>
                            <div className="read_more-link">
                                Read more
                                <div className="go_arrow" />
                            </div>
                        </div>
                        <p>Renders will be uploaded to the <span className="highlight-span" style={{ textDecoration: 'underline', cursor: 'pointer' }}>Homepage</span> when ready.</p>
                    </div>

                </div>


            </div>
        </>
    )
}


export function CreateMaskSection() {
    return (
        <>
            <div className="extended_help-header">
                <div className="extended_help-header_section">
                    <p className="extended_help-header_section-help" onClick={() => setHelpSection({ section: HelpSections.WHAT_IS, itemId: null })}>Help</p>
                    <p>/</p>
                    <p className="extended_help-header_section_title">How to create and edit object masks</p>

                </div>
            </div>

            <div className="extended_help_create-masks">
                <div className="extended_help_home">
                    <p>When AI renders your image, it uses the objects you specify with masks and their definitions.</p>
                    <p>Use an AI toolkit to identify objects you want to define, including design elements like materials and products, as well as entourage like people and vegetation.</p>
                    <p>Generalize objects as needed. For example, define a window as a single object or break it down into parts like the frame, handle, sill, and glass. There are no strict rules.</p>
                </div>
                <div className="extended_help_home_quick-start">
                    <p className="section_title">Quick start</p>
                    <div className="quick-start_item">
                        <p>1.</p>
                        <p>Enable the <span className="highlight-span">Create/Edit object mask</span> tool.</p>
                    </div>
                    <div className="quick-start_item">
                        <p>2.</p>
                        <p>Use the appropriate tool to mask the object. Mix tools as needed.</p>
                    </div>
                    <div className="quick-start_item">
                        <p>3.</p>
                        <p>Click <span className="highlight-span">Done</span> when ready or <span className="highlight-span">Cancel</span> to revert the entire mask editing session.</p>
                    </div>
                    <div className="quick-start_item">
                        <p>4.</p>
                        <p>To return to editing, select the mask and enable <span className="highlight-span">Create/Edit object mask</span> again or double-click the mask.</p>
                    </div>
                    <div className="quick-start_item">
                        <p>5.</p>
                        <p>Click the <span className="highlight-span">Render</span> button to configure the rendering settings, and submit.</p>
                    </div>

                </div>

                <div className="extended_help_home">
                    <div className="dropdown_items-wrapper">
                        <p>Mask Editing Tools</p>
                        {editingToolsItems.map((item) => (
                            <HelpDropdown item={item} key={item.title} />
                        ))}
                    </div>
                </div>


            </div>
        </>
    )
}

export function InterfaceSection() {
    return (
        <>
            <div className="extended_help-header">
                <div className="extended_help-header_section">
                    <p className="extended_help-header_section-help" onClick={() => setHelpSection({ section: HelpSections.WHAT_IS, itemId: null })}>Help</p>
                    <p>/</p>
                    <p className="extended_help-header_section_title">Tour the interface</p>

                </div>
            </div>
            <div className="extended_help_iterface">
                {interfaceDropdownItems.map((item) => (
                    <HelpDropdown item={item} key={item.title} />
                ))}
            </div>
        </>
    )
}


export function AccountSection() {
    return (
        <>
            <div className="extended_help-header">
                <div className="extended_help-header_section">
                    <p className="extended_help-header_section-help" onClick={() => setHelpSection({ section: HelpSections.WHAT_IS, itemId: null })}>Help</p>
                    <p>/</p>
                    <p className="extended_help-header_section_title">Manage your account</p>

                </div>
            </div>
            <div className="extended_help_iterface">
                {accountSettingsItems.map((item) => (
                    <HelpDropdown item={item} key={item.title} />
                ))}
            </div>
        </>
    )
}

export function DescribeObjectsSection() {

    const helpSection = useStore($helpSection)

    useEffect(() => {
        setTimeout(() => {
            if (helpSection.itemId) {
                const element = document.getElementById(helpSection.itemId);
                element?.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        }, 300);

    }, [helpSection.itemId])


    return (
        <>
            <div className="extended_help-header">
                <div className="extended_help-header_section">
                    <p className="extended_help-header_section-help" onClick={() => setHelpSection({ section: HelpSections.WHAT_IS, itemId: null })}>Help</p>
                    <p>/</p>
                    <p className="extended_help-header_section_title">How to describe objects</p>

                </div>
            </div>
            <div className="extended_help_create-masks">
                <p>When AI renders your image, it uses the objects you specify with masks and their definitions. Define indicated objects in the table. Write a definition in words and add any reference files of links relevant to the object.</p>
                <div className="extended_help_home">
                    <div className="section_title-desrc_obj">Text</div>
                    <p>In the text field provide either the exact product name (ex: Wassily Chair by Marcel Breuer) or a general description (ex: greyish hammered concrete). Be as precise and accurate as needed. There is no rule.</p>
                </div>
                <div className="extended_help_home">
                    <div className="section_title-desrc_obj">Attachments</div>
                    <p>To Add attachments click <span className="highlight-span">Add</span> and choose relevant attachment type. You can add one attachment in one adding procedure. Repeat adding to add as many as you need. </p>
                    <span className="highlight-span">Please provide as many beta-marked attachments as possible.</span>
                    <div className="read_more-link">
                        read important release note
                        <div className="go_arrow" />
                    </div>
                </div>

                <div className="extended_help_home">
                    <div className="dropdown_items-wrapper">
                        <p>Attachment types</p>
                        {attachmentTypesItems.map((item) => (
                            <HelpDropdown item={item} key={item.title} />
                        ))}
                    </div>
                </div>

            </div>

        </>
    )
}


export function GroupSection() {
    return (
        <>
            <div className="extended_help-header">
                <div className="extended_help-header_section">
                    <p className="extended_help-header_section-help" onClick={() => setHelpSection({ section: HelpSections.WHAT_IS, itemId: null })}>Help</p>
                    <p>/</p>
                    <p className="extended_help-header_section_title">How to group identical objects to define together</p>

                </div>
            </div>
            <div className="extended_help_create-masks">
                <div className="item-list">
                    <p>In many cases, your image may contain identical objects. Picture similar windows or chairs around the table.</p>
                    <p>To define identical objects together, <span className="highlight-span">Group</span> them. During the rendering process group definition will be applied to every object in the group.</p>
                </div>


                <div className="help_rendering-item">


                    <div className="extended_help_home_quick-start">
                        <p className="section_title">Quick start</p>
                        <div className="quick-start_item">
                            <p>1.</p>
                            <div className="quick-start_item-column">
                                <p>Select the objects you want to define together.</p>
                                <div className="read_more-link" onClick={() => setHelpSection({ section: HelpSections.MASK_SELECTION, itemId: null })}>
                                    read more on how to select
                                    <div className="go_arrow" />
                                </div>
                            </div>

                        </div>
                        <div className="quick-start_item">
                            <p>2.</p>
                            <p>Click on <span className="highlight-span">Create/Edit</span> group. Group editing mode is enabled.</p>
                        </div>
                        <div className="quick-start_item">
                            <p>3.</p>
                            <p>Add or remove objects to the group: drag rows in and out of the group in the table or select/deselect masks in the viewport. Use the same <span className="underline_span" onClick={() => setHelpSection({ section: HelpSections.MASK_SELECTION, itemId: null })}>selection principles</span> as with common mask selection.</p>
                        </div>
                        <div className="quick-start_item">
                            <p>4.</p>
                            <div className="extended_help_home_quick-start">
                                <p>Refine the list of identified objects and describe them in the table.</p>
                                <div className="quick-start_item">
                                    <p>a.</p>
                                    <p>Click <span className="highlight-span">Include</span> to keep and move all preexisting definitions to the group.</p>
                                </div>
                                <div className="quick-start_item">
                                    <p>b.</p>
                                    <div className="extended_help_home_quick-start">
                                        <p>Click <span className="highlight-span">Discard</span> to reject and delete all preexisting definitions.</p>
                                        <p>You must decide whether to include or discard preexisting definitions before finishing group editing session. Until you decide, you can remove the object from the group unedited with preexisting definitions.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="quick-start_item">
                            <p>5.</p>
                            <p>Click <span className="highlight-span">Done</span> to finish editing the group or <span className="highlight-span">Cancel</span> to discard the entire editing session. To revert to editing select the group and click <span className="highlight-span">Create/Edit group</span>.</p>
                        </div>

                    </div>
                    <div className="item-list">
                        <p>There is an <span className="highlight-span">Ungroup</span> command. When you ungroup every group element inherit group definition.</p>
                        <p>In any case you would require to remove all definitions from the object or group select the designated row and click <span className="highlight-span">Undefine</span>.</p>

                    </div>
                </div>
            </div>

        </>
    )
}


export function HelpDropdown({ item }: { item: SectionItemI }) {
    const ref = useRef<HTMLDivElement | null>(null);
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const dropDownElementsHeight = useChildElementsHeight({ ref: ref })
    const helpSection = useStore($helpSection)

    useEffect(() => {
        if (helpSection.itemId === item.id) setIsOpen(true)
    }, [helpSection.itemId, item.id])

    return (

        <div className="interface_dropdown_wrapper">
            <div className="interface_dropdown" onClick={() => setIsOpen((prev) => !prev)}>
                <div className="dropdown-title_wrapper">
                    <p className="dropdown-title">{item.title}</p>
                    {item.isBeta && <div className="dropdown-title-beta_marker">beta</div>}
                </div>

                <div className={`dropdown_icon-${isOpen ? 'minus' : 'plus'}`} />

            </div>
            <div className='dropdown_item' style={{ maxHeight: isOpen ? dropDownElementsHeight : 0 }} ref={ref} id={item.id}>
                <div className="dropdown_item-info" >
                    {item.image !== undefined && <div className="dropdown_item-info-image" />}
                    <div className="item-list">
                        {getText(item)}
                        {item.readMoreAbout &&
                            <div className="read_more-link" onClick={() => { if (item.readMoreAbout) setHelpSection({ section: item.readMoreAbout.type, itemId: null }) }}>
                                read more {item.readMoreAbout.title}
                                <div className="go_arrow" />
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>

    )

}


import { useCallback, useEffect, useMemo, useState } from 'react';
import { useStore } from 'effector-react';
import classNames from 'classnames';
import { ReactComponent as NoteSVG } from './icons/note.svg';
import { ReactComponent as InfoSVG } from './icons/info.svg';
import { ReactComponent as CheckSVG } from './icons/check.svg';
import { ReactComponent as ArrowSVG } from './icons/arrow.svg';
import Qualities from './Qualities';
import Button from 'Components/Button';
import Environment from './Environment';
import { ManagerInitStep, useInitiator, useManager } from 'entities/sketch/Manager';
import { $sketchFormChecked, setSketchFormChecked } from 'entities/sketch';
import { LeftSidePanel, setLeftSidePanel } from 'entities/leftSidePanel';
import './DownloadForm.scss';


enum SpaceType {
    EXTERIOR = 'EXTERIOR',
    INTERIOR = 'INTERIOR',
}

enum Tab {
    QUALITIES = 'QUALITIES',
    ENVIRONMENT = 'ENVIRONMENT',
}

export class DownloadFormTemplate {
    materialDiversity = '';
    prevailingMaterials = '';
    dominantTextures = '';
    dominantSurfaces = '';
    detailIntegration = '';
    paletteDiversity = '';
    paletteTemperature = ''
    primaryTone = '';
    secondaryTone = '';
    time = '';
    season = '';
    weather = '';
    scenery = '';
    country = '';
}

const formPrompt: any = {
    'Rather monochrome than colorful': 'Design is monochrome. There is a minimum of tertiary tones in design materials besides the primary and secondary tone.',
    'Rather colorful than monochrome': 'There are 3 accent tertiary tones in design materials besides the primary and secondary tone.',
    'Rather uniform than diverse': 'scarce and minimalistic',
    'Rather diverse than uniform': 'diverse and rich',
    'Rather natural than artificial': 'natural',
    'Rather artificial than natural': 'artificial',
    'Rather plain than active': 'have plain, subtle and minimalistic textures',
    'Rather active than plain': 'have bold, active and rich textures',
    'Rather polished than matte': 'are smooth, glossy, polished with noticeable shine',
    'Rather matte than shiny': 'are matte, coarse, rough and dull',
    'Rather integrated than utilitarian': 'Design details and elements are richly integrated to enhance the overall design',
    'Rather utilitarian than integrated': 'Design details and elements are purely utilitarian',
    'Rather cold than warm': 'cold',
    'Rather warm than cold': 'warm',
    [SpaceType.EXTERIOR]: 'exterior',
    [SpaceType.INTERIOR]: 'interior',
    '#FFFFFF': 'white',
    '#808080': 'ash grey',
    '#708090': 'slate grey',
    '#36454F': 'charcoal',
    '#000000': 'black',
    '#FF0000': 'red',
    '#FFA500': 'orange',
    '#FFFF00': 'yellow',
    '#008000': 'green',
    '#ADD8E6': 'light blue',
    '#0000FF': 'blue',
    '#800080': 'purple',
    '#B87333': 'copper',
    '#A8A9AD': 'nickel',
    '#C0C0C0': 'silver',
    '#F5F5DC': 'beige',
    '#D2B48C': 'tan',
    '#D2691E': 'terracotta',
    '#654321': 'dark brown',
    '#D6CBB5': 'dark alabaster',
    '#C8BD93': 'dark sand',
    '#B7E3B1': 'sage',
    '#355E3B': 'hunter green',
    '#5B7C99': 'slate blue',
};

export default function DownloadForm() {
    const checked = useStore($sketchFormChecked);
    const prototyping = useInitiator('prototyping');
    const manager = useManager('self');
    const initStep = useInitiator('initStep');
    const [spaceType, setSpaceType] = useState(SpaceType.EXTERIOR);
    const [tab, setTab] = useState(Tab.QUALITIES);
    const [form, setForm] = useState(() => Object.assign({}, new DownloadFormTemplate()));

    const createPrototype = useCallback(() => {
        const keys: Array<keyof DownloadFormTemplate> = ['materialDiversity', 'prevailingMaterials', 'dominantTextures', 'dominantSurfaces', 'detailIntegration', 'paletteDiversity', 'paletteTemperature', 'primaryTone', 'secondaryTone', 'time', 'season', 'weather', 'scenery', 'country'];
        if (keys.some(key => !form[key])) /* return  */setSketchFormChecked(true);
        if (initStep === ManagerInitStep.CREATED) return setSketchFormChecked(true);

        const prompt = `Photo of an ${formPrompt[spaceType]}, set in ${form.time}, during the ${form.season} in ${form.country}, with ${form.weather} conditions, set in the context of a ${form.scenery}.
The primary tone of materials and finishes in ${formPrompt[spaceType]} design is ${formPrompt[form.primaryTone]}.
The secondary tone of materials and finishes in ${formPrompt[spaceType]} design is ${formPrompt[form.secondaryTone]}.
${formPrompt[form.paletteDiversity]}.
The overall color palette in regards of color temperature of ${formPrompt[spaceType]} design is ${formPrompt[form.paletteTemperature]}.
${formPrompt[spaceType]} materials and finishes variety is ${formPrompt[form.materialDiversity]} of the ${formPrompt[spaceType]}.
${formPrompt[spaceType]} materials and finishes are mainly ${formPrompt[form.prevailingMaterials]}. 
All materials and finishes of the ${formPrompt[spaceType]} ${formPrompt[form.dominantTextures]}.
Surfaces of the ${formPrompt[spaceType]} materials and finishes ${formPrompt[form.dominantSurfaces]}.
${formPrompt[form.detailIntegration]}.`;

        manager.initiator.makePrototype(prompt);
    }, [form, manager, initStep, spaceType]);

    useEffect(() => void setSketchFormChecked(false), []);

    const qualitiesErrored = useMemo(() => checked && (['materialDiversity', 'prevailingMaterials', 'dominantTextures', 'dominantSurfaces', 'detailIntegration', 'paletteDiversity', 'paletteTemperature', 'primaryTone', 'secondaryTone'] as Array<keyof DownloadFormTemplate>).some(key => !form[key]), [checked, form]);
    const environmentErrored = useMemo(() => checked && (['time', 'season', 'weather', 'scenery', 'country'] as Array<keyof DownloadFormTemplate>).some(key => !form[key]), [checked, form]);

    return (
        <div className="download-form">
            <div className="download-form__note">
                <NoteSVG className="download-form__note-icon" />
                <div className="download-form__note-text">
                    <div>Load design image and set design materials and context.</div>
                    <div>Click Prototype to check my interpretation. Use undo/redo to choose the prototyping iteration that fits your design composition. </div>
                    <div>Next, click proceed to details explanation. Together, we'll identify and define each object refining the prototype for final rendering. </div>
                    <div>Hover buttons for the tooltips & read more in help.</div>
                </div>
            </div>
            <div className="download-form__space-type">
                <div className="download-form__space-type-form">
                    <div className={classNames({ 'download-form__space-type-option': true, 'download-form__space-type-option_selected': spaceType === SpaceType.EXTERIOR })} onClick={() => setSpaceType(SpaceType.EXTERIOR)}>
                        <CheckSVG className={classNames({ invisible: spaceType === SpaceType.INTERIOR })} />
                        <div>Exterior</div>
                    </div>
                    <div className={classNames({ 'download-form__space-type-option': true, 'download-form__space-type-option_selected': spaceType === SpaceType.INTERIOR })} onClick={() => setSpaceType(SpaceType.INTERIOR)}>
                        <CheckSVG className={classNames({ invisible: spaceType === SpaceType.EXTERIOR })} />
                        <div>Interior</div>
                    </div>
                </div>
                <InfoSVG className="download-form__space-type-info" />
            </div>
            <div className="download-form__top-bar">
                <div className={classNames({ 'download-form__top-bar-option': true, 'download-form__top-bar-option_selected': tab === Tab.QUALITIES, 'download-form__top-bar-option_errored': qualitiesErrored })} onClick={() => setTab(Tab.QUALITIES)}>GENERALIZED MATERIAL QUALITIES</div>
                <div className="download-form__top-bar-gap" />
                <div className={classNames({ 'download-form__top-bar-option': true, 'download-form__top-bar-option_selected': tab === Tab.ENVIRONMENT, 'download-form__top-bar-option_errored': environmentErrored })} onClick={() => setTab(Tab.ENVIRONMENT)}>ENVIRONMENT AND TIME</div>
                <div className="download-form__top-bar-border" />
            </div>
            <div className="download-form__tab">
                {tab === Tab.QUALITIES && <Qualities form={form} setForm={setForm} checked={checked} />}
                {tab === Tab.ENVIRONMENT && <Environment form={form} setForm={setForm} checked={checked} />}
            </div>
            <div className="download-form__footer">
                {prototyping
                    ?
                    <Button size="medium" color="white" onClick={() => manager.initiator.cancelPrototyping()}>CANCEL</Button>
                    :
                    <Button size="medium" color="white" onClick={createPrototype}>PROTOTYPE</Button>
                }
                <Button disabled={initStep !== ManagerInitStep.PROTOTYPE_LOADED} size="medium" color="dark" className="download-form__footer-big-black-button" onClick={() => setLeftSidePanel(LeftSidePanel.IRREVERSIBLE_STEP)}>
                    <div>PROCEED TO REFINE</div>
                    <ArrowSVG />
                </Button>
            </div>
        </div>
    );
}

import { createStore } from 'effector';


export class RenderSettings {
    primaryTone = '';
    secondaryTone = '';
    colorMode = '';
    paletteTemperature = '';
    materialDiversity = '';
    materialTypePreference = '';
    textureDominance = '';
    surfaceFinish = '';
    detailIntegration = '';
    time = '';
    season = '';
    weather = '';
    scenery = '';
    country = '';
};

export const $renderSettings = createStore<RenderSettings>(JSON.parse(JSON.stringify(new RenderSettings())));
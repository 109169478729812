import { createEvent, createStore } from 'effector';
import { magicAuthFx } from 'entities/user';


export enum LeftSidePanel {
    NONE = 'NONE',
    AUTH = 'AUTH',
    ACCOUNT = 'ACCOUNT',
    SUBMIT_FOR_RENDERING = 'SUBMIT_FOR_RENDERING',
    SAVE_BEFORE_CLOSING = 'SAVE_BEFORE_CLOSING',
    IRREVERSIBLE_STEP = 'IRREVERSIBLE_STEP',
}

export const setLeftSidePanel = createEvent<LeftSidePanel>();

export const $leftSidePanel = createStore<LeftSidePanel>(LeftSidePanel.NONE);

$leftSidePanel
    .on(setLeftSidePanel, (_, payload) => payload)
    .on(magicAuthFx.doneData, state => state === LeftSidePanel.AUTH ? LeftSidePanel.NONE : state);

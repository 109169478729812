import { useEffect, useMemo, useState } from 'react';
import { useStore } from 'effector-react';
import classNames from 'classnames';
import { ReactComponent as SavingSVG } from './icons/saving.svg';
import { ReactComponent as SavedSVG } from './icons/saved.svg';
import { $saving, SavingState, setSavingState } from 'entities/saving';
import './Saving.scss';


export default function Saving() {
    const savingState = useStore($saving);

    const component = useMemo(() => {
        switch (savingState) {
            case SavingState.SAVING:
                return <SavingComponent />;
            case SavingState.SAVED:
                return <Saved />;
            case SavingState.NONE:
                return null;
            default:
                throw new Error('Unknown saving state');
        }
    }, [savingState]);

    return component;
}


function SavingComponent() {
    return (
        <div className="saving" >
            <SavingSVG className="saving__saving-svg" />
            <div>Saving...</div>
        </div>
    );
}

function Saved() {
    const [opacity, setOpacity] = useState(false);
    useEffect(() => {
        const timeout = setTimeout(() => setSavingState(SavingState.NONE), 2000);

        return () => clearTimeout(timeout);
    }, []);

    useEffect(() => {
        const timeout = setTimeout(() => setOpacity(true), 1000);

        return () => clearTimeout(timeout);
    }, []);

    return (
        <div className={classNames({ saving: true, saving__saved: opacity })} >
            <SavedSVG />
            <div>Saved</div>
        </div >
    );
}
